import React from 'react';

const PeopleIconSvg = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 65 65" >
    <path className="st0" d="M1.2,39.4C1.2,39.4,1.2,39.4,1.2,39.4c0.8,0,1.2-0.8,1.3-1.1c1.9-3.8,4-7.4,6.3-11c-2,9.8-1.8,19.8-1.4,28.7
		c0.1,1.2,0.1,2.7,0.9,3.9c0.5,0.7,1.2,1.3,2.1,1.5c0.2,0,0.4,0.1,0.5,0.1c0.5,0,0.9-0.1,1.3-0.4c1.4-1,1.2-3,1.1-4.1
		c-0.4-4.5,0.2-9.1,0.9-13.8c0.3,4.9,0.7,9.5,1.2,13.2c0.2,1.4,0.4,2.8,1.2,3.8c1.4,1.7,3.6,1.5,4.5,0.1c0.6-1,0.4-2.1,0.5-10.5
		c0-1.7,0.1-3.3,0.1-5c0.1-5.4,0.3-11.1-0.3-16.7c-0.1-1.1-0.3-2.2-0.5-3.4c1.8,1.6,3.4,3.6,4.6,5.7c0,0.1,0.1,0.1,0.2,0.2
		c-0.5,4.4-0.4,8.9-0.4,13c0.1,4,0.1,8.1,0.5,12.1c0.1,1.2,0.2,2.3,0.8,3.3c0.5,0.9,1.5,1.7,2.7,1.7c0.2,0,0.3,0,0.5-0.1
		c1-0.2,1.8-1.1,2.2-2.2c0.3-1,0.2-1.9,0.2-2.9c-0.2-4-0.3-8.1-0.3-12.1c0-1.1,0-2.2,0.2-3.2l0-0.3c0-0.2,0-0.4,0.1-0.6
		c1.2,3.5,1.4,7.4,1.5,11.1l0,0.5c0,0.8,0,1.6,0,2.4c0,0.9,0,1.9,0,2.9c0.1,1.4,0.2,3.6,2.2,4.3c0.9,0.3,1.7,0.2,2.4-0.3
		c1.3-1,1.8-3.2,1.8-4.9c0-4.3-0.1-8.6-0.2-12.8L40,39.4c0-1.3,0-2.6,0-3.8c0-1.9,0-3.9-0.1-5.9c0-0.1-0.1-0.4-0.1-0.9
		c0.1,0,0.2-0.1,0.2-0.2c1-1.6,2.3-3.1,3.6-4.5c-0.6,3-1.1,6.1-1.2,9.2c-0.1,3.2,0.1,6.3,0.3,9.4l1.1,15c0,0.7,0.1,1.5,0.4,2.2
		c0.3,0.9,1,1.5,1.8,1.7c1.3,0.4,2.5-0.5,3.1-1.6c0.5-1,0.6-2.2,0.6-3.2l0.4-13.4c0.6,4.6,1,9.3,1.1,13.9c0,1.1,0.1,2.4,0.9,3.4
		c0.6,0.7,1.5,1.1,2.4,1.1c0.3,0,0.6,0,0.9-0.1c1.1-0.3,2-1.3,2.4-2.5c0.3-1,0.3-2,0.3-2.9l-0.4-11c-0.2-6.1-0.5-12-1.3-17.8
		l5.3,10.9c0.2,0.4,0.4,0.9,0.9,1.3c0.5,0.4,1.3,0.5,1.9,0c0.7-0.6,0.5-1.5,0.4-1.9c-1.1-5.5-2.6-11.3-5.6-16.3c0.1,0,0.2,0,0.3-0.1
		c0.5-0.2,0.9-0.7,1-1.2c0.1-0.5,0-1-0.3-1.4c-0.2-0.3-0.5-0.6-1-0.9c-1-0.6-2.2-0.9-3.4-0.7c-0.7-0.5-1.4-1-2.2-1.4
		c1.6-1.2,2.6-4.2,2.7-6.1c0.2-3-1.4-5.2-4-6c-2.6-0.8-6.1-0.2-8.1,1.6c-1.2,1.1-1.9,2.7-1.8,4.1c0.1,1.1,0.5,2.1,1,3
		c0.6,1.2,1.3,2.1,2,2.8c0.2,0.2,0.4,0.3,0.6,0.5c-0.2,0.1-0.4,0.1-0.5,0.2c-1,0.5-2,1.2-3,2.2c-1.8-0.8-3.5-1.4-5.2-1.9
		c0.7-0.7,1.2-1.7,1.5-2.4c0.5-1.2,0.8-2.3,0.8-3.3C39.8,9,39.6,7.8,39,6.9c-0.7-1.2-1.9-2.1-3.3-2.5c-2.5-0.8-6.1-0.2-8.1,1.6
		c-2.1,1.9-2.4,4.2-0.8,7.1c0.7,1.2,1.3,2.1,2,2.8c0.2,0.2,0.4,0.3,0.6,0.5c-0.8,0.3-1.6,0.6-2.4,0.8c-1.8,0.5-3.6,0.9-5.4,1
		c-0.8-0.6-1.6-1.1-2.5-1.5c0,0,0.1-0.1,0.1-0.1c1-0.8,1.5-1.9,1.9-2.8c0.5-1.2,0.8-2.3,0.8-3.3c0.2-2.9-1.4-5.1-4-6
		c-2.5-0.8-6.1-0.2-8.1,1.6C7.4,8,7.5,10.4,8.9,13c0.7,1.2,1.3,2.1,2,2.8c0.2,0.2,0.4,0.4,0.6,0.5c-0.6,0.4-1.2,0.8-1.8,1.3
		c-0.7-0.2-1.2-0.3-1.8-0.2c-1.9,0.2-2.8,1.8-2.4,3.1c0.2,0.5,0.6,0.9,1.1,1.1c-0.7,1.1-1.2,2.3-1.7,3.4c-2.1,4.5-3.6,8.4-4.7,12.1
		c-0.1,0.3-0.2,0.8-0.1,1.3C0.1,38.9,0.6,39.4,1.2,39.4z M9.8,60c0.1-0.1,0.2-0.2,0.2-0.3c0.3,0.2,0.5,0.3,0.8,0.4
		c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0C10.4,60.3,10.1,60.2,9.8,60z M10.8,58.1C10.9,58.1,10.9,58.2,10.8,58.1
		c0.2,0,0.3,0,0.3-0.1l0.1-0.3c0.4,0.2,0.8,0.4,1.2,0.7c0,0.4-0.1,0.7-0.2,1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.3-0.2-0.3c-0.1,0-0.3,0-0.3,0.2c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.1-0.4-0.2-0.6-0.3
		c0,0,0.1-0.1,0.1-0.1C11,58.3,11,58.2,10.8,58.1z M11.2,55.8l0.3-0.6l0.5,0.3l-0.4,0.8c-0.2-0.1-0.4-0.2-0.6-0.3
		C11.1,55.9,11.2,55.9,11.2,55.8z M13.4,39.9l0.4,0.2c0,0.2-0.1,0.3-0.1,0.5c0,0,0,0-0.1,0.1l0,0l-0.5-0.3c0,0-0.1,0-0.1,0
		C13.1,40.2,13.2,40,13.4,39.9z M13.8,39.5L13.8,39.5c-0.1-0.1,0-0.1,0-0.1C13.8,39.5,13.8,39.5,13.8,39.5z M13.1,44.4l-0.2-0.1
		l0.2-0.3C13.1,44.1,13.1,44.2,13.1,44.4z M12.8,46.1c0,0.2,0,0.4-0.1,0.6c-0.1-0.1-0.2-0.2-0.4-0.2C12.5,46.4,12.6,46.2,12.8,46.1
		C12.8,46.1,12.8,46.1,12.8,46.1z M12.7,47.7c0,0.1,0,0.1,0,0.2l-0.1,0C12.6,47.8,12.6,47.8,12.7,47.7z M12.6,48.5
		C12.6,48.5,12.6,48.5,12.6,48.5c-0.1,0.1-0.2,0.2-0.3,0.3L12,48.6c0.1-0.1,0.2-0.2,0.3-0.4L12.6,48.5z M12.5,49.5
		c0,0.2,0,0.4-0.1,0.6c-0.1-0.1-0.2-0.1-0.4-0.2C12.2,49.8,12.3,49.6,12.5,49.5L12.5,49.5z M12.3,52c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0
		l0,0l-0.4-0.3l0.3-0.5L12.3,52z M12.3,53.8C12.3,53.7,12.3,53.7,12.3,53.8l0-0.1C12.3,53.7,12.3,53.7,12.3,53.8z M12.3,54.3
		c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0l0,0l-0.5-0.3l0.3-0.6C12.1,54.2,12.2,54.2,12.3,54.3z M12.4,55.9c0,0.2,0,0.5,0,0.7
		c-0.1,0-0.2-0.1-0.3-0.2L12.4,55.9z M12.4,57.2c0,0.2,0,0.5,0,0.7c-0.3-0.2-0.6-0.4-0.9-0.5l0.3-0.5C12,57,12.2,57.1,12.4,57.2z
		 M12,59.9c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.2,0.1C11.9,59.8,12,59.8,12,59.9
		C12,59.8,12.1,59.8,12,59.9C12.1,59.8,12,59.8,12,59.9z M11.6,60.3c-0.1,0-0.1,0.1-0.2,0.1c0,0,0-0.1,0.1-0.1
		C11.5,60.3,11.5,60.3,11.6,60.3z M11.3,59.4c0,0.1-0.1,0.2-0.1,0.3c-0.3-0.1-0.5-0.2-0.7-0.3c0.1-0.1,0.2-0.2,0.2-0.3
		C10.8,59.2,11,59.3,11.3,59.4z M25.7,28.7C25.7,28.6,25.7,28.6,25.7,28.7c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0,0.1
		C25.9,29,25.8,28.8,25.7,28.7z M24.2,20.2c-0.1,0-0.1,0.1-0.2,0.1L24,19.8c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0.1
		C24.2,19.9,24.2,20,24.2,20.2z M24.2,20.7C24.2,20.7,24.2,20.7,24.2,20.7C24.2,20.7,24.2,20.7,24.2,20.7
		C24.2,20.7,24.2,20.7,24.2,20.7z M25,20.7l-0.2,0.1c0-0.1,0-0.2,0-0.4c0.1-0.1,0.3-0.1,0.4-0.2c-0.1,0-0.2,0.2-0.2,0.3
		C25,20.7,25,20.7,25,20.7z M26,21.2l-0.3,0.2c0,0-0.1,0-0.1,0c0-0.1-0.1-0.2-0.1-0.4l0.3-0.2c0,0,0,0,0,0
		C25.9,20.9,25.9,21,26,21.2z M25.1,21.4c-0.1,0-0.2,0-0.3,0l0.2-0.1C25.1,21.3,25.1,21.3,25.1,21.4z M27.2,22.1
		c0.2,0,0.4-0.1,0.5-0.1c-0.3,0.8-0.6,1.6-0.8,2.5c-0.4-0.7-0.9-1.4-1.4-2.1C26.1,22.3,26.7,22.2,27.2,22.1z M26.1,28.3
		c0,0,0,0.1,0,0.1C26.1,28.3,26,28.3,26.1,28.3C26,28.3,26.1,28.2,26.1,28.3C26.1,28.2,26.1,28.2,26.1,28.3z M26.6,25.8
		C26.6,25.8,26.6,25.9,26.6,25.8c-0.1,0-0.1,0-0.1,0c0-0.1,0-0.1,0-0.2C26.5,25.7,26.6,25.7,26.6,25.8z M26.5,26.4
		c0,0.1,0,0.1,0,0.2c0,0,0,0-0.1-0.1C26.4,26.5,26.4,26.5,26.5,26.4C26.4,26.4,26.4,26.4,26.5,26.4z M26.3,27.3c0,0.1,0,0.1,0,0.2
		c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1C26.2,27.4,26.2,27.3,26.3,27.3z M34.4,43.6c0,0-0.1-0.1-0.1-0.1c0-0.2-0.1-0.4-0.1-0.7
		c0-0.1,0.1-0.1,0.1-0.2l0.3,0.4c0,0,0,0,0.1,0L34.4,43.6z M35.1,57.7c0.1,0.1,0.2,0.2,0.3,0.2l-0.2,0.3
		C35.1,58.1,35.1,57.9,35.1,57.7z M34.9,55.8C34.9,55.7,34.9,55.7,34.9,55.8L34.9,55.8L34.9,55.8z M34.7,47.3l0.1-0.2
		c0.1,0.2,0.3,0.3,0.4,0.5c0,0,0,0,0.1,0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.2,0.3-0.3,0.5C34.7,47.9,34.7,47.6,34.7,47.3z
		 M33.4,39.7C33.5,39.8,33.5,39.8,33.4,39.7l0,0.1C33.5,39.8,33.4,39.8,33.4,39.7z M37.5,59.5c0.1,0,0.2,0,0.3,0.1
		c-0.1,0.1-0.3,0.1-0.4,0.2C37.4,59.6,37.5,59.5,37.5,59.5z M39.1,24.2c0-0.2,0-0.3-0.1-0.5l0.1,0.1C39.1,23.9,39.1,24.1,39.1,24.2
		C39.1,24.2,39.1,24.2,39.1,24.2z M41.6,19c-0.1,0-0.1,0-0.2,0c0-0.2-0.1-0.3-0.1-0.5c0.2,0.1,0.3,0.1,0.5,0.2
		C41.7,18.8,41.6,18.9,41.6,19z M40.6,20.3c0,0-0.1,0-0.1,0c0-0.1,0-0.2-0.1-0.3c0.1,0,0.2,0.1,0.4,0.1
		C40.7,20.1,40.6,20.2,40.6,20.3z M39.8,21.4C39.8,21.4,39.7,21.3,39.8,21.4c-0.1,0-0.1-0.1-0.1-0.1L39.8,21.4
		C39.8,21.3,39.8,21.3,39.8,21.4z M38.9,22.8c0-0.1,0-0.3,0-0.4c0.1,0,0.2,0,0.3,0C39.1,22.5,39,22.6,38.9,22.8z M39.4,26.7
		c0.1-0.1,0.3-0.2,0.4-0.2l0.2,0.4c-0.1,0.2-0.3,0.4-0.4,0.6C39.5,27.2,39.5,27,39.4,26.7z M39.4,26.1c0-0.2-0.1-0.4-0.1-0.5
		c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.1,0,0.2l0.1,0.3C39.6,26,39.5,26.1,39.4,26.1z M52.4,59.1l0.3,0.2l-0.1,0.2
		C52.5,59.4,52.5,59.3,52.4,59.1z M52.2,57.3c0,0,0-0.1,0-0.1l0.1,0.1c0,0.1-0.1,0.1-0.1,0.2C52.2,57.4,52.2,57.3,52.2,57.3z
		 M52.1,55.1c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2C52.1,55.4,52.1,55.3,52.1,55.1z M52,52.9c0.1,0.1,0.3,0.3,0.4,0.4
		L52,53.8C52,53.5,52,53.2,52,52.9z M51.8,51l0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.4C51.9,51.6,51.9,51.3,51.8,51z M51.7,48.9
		c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.3-0.3,0.4C51.7,49.4,51.7,49.2,51.7,48.9z M51.5,46.9c0.1,0.1,0.2,0.2,0.3,0.3
		c-0.1,0.1-0.1,0.2-0.2,0.3C51.5,47.3,51.5,47.1,51.5,46.9z M51.2,44.5l0.2,0.2c0,0.1-0.1,0.1-0.1,0.2
		C51.2,44.7,51.2,44.6,51.2,44.5z M50.9,41.8c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3C50.9,42.2,50.9,42,50.9,41.8z
		 M50.5,39.3C50.5,39.3,50.5,39.3,50.5,39.3c0.1,0.1,0,0.1,0,0.2C50.5,39.4,50.5,39.4,50.5,39.3z M55.2,60.7c0,0-0.1,0-0.1,0
		c0.1-0.1,0.1-0.2,0.2-0.4l0.3,0.2C55.5,60.5,55.3,60.6,55.2,60.7z M57.1,52.8l-0.2-0.1c0-0.1,0.1-0.1,0.2-0.2L57.1,52.8z
		 M57.1,54.4L57.1,54.4C57.1,54.4,57.1,54.3,57.1,54.4L57.1,54.4z M57.2,57.6c-0.1-0.1-0.2-0.2-0.3-0.2l0.3-0.5
		C57.2,57.1,57.2,57.3,57.2,57.6z M59.5,19.2c0.1,0.2,0.2,0.5,0.2,0.7c0,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.6-0.1
		c0,0,0,0-0.1,0c-0.3-0.5-0.6-1-1-1.5c-0.2-0.3-0.4-0.5-0.6-0.8c0.6,0.1,1.2,0.3,1.8,0.6C59.1,18.8,59.3,19,59.5,19.2z M44.7,12.5
		c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1C44.8,12.6,44.8,12.5,44.7,12.5z M45.4,12.8c0.3,0.1,0.7,0.2,1,0.3
		c-0.2,0.3-0.5,0.5-0.8,0.7c-0.2-0.2-0.4-0.5-0.6-0.8C45.2,13,45.3,12.9,45.4,12.8z M48.4,14.3c-0.1,0.2-0.3,0.3-0.4,0.5
		c-0.3,0.1-0.6,0.1-0.9,0.1c0,0-0.1,0-0.1,0c0.2-0.1,0.4-0.3,0.5-0.4C47.8,14.4,48.1,14.3,48.4,14.3z M46.5,14.6
		c0,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0,0.2,0C46.6,14.6,46.5,14.6,46.5,14.6z M48.2,15.2c0.6-0.1,1.1-0.3,1.6-0.5
		c-0.1,0.2-0.3,0.3-0.4,0.5c-0.4,0.1-0.8,0.3-1.2,0.4c-0.1,0-0.2-0.1-0.2-0.1C48,15.4,48.1,15.3,48.2,15.2z M51.6,11.8
		C51.6,11.8,51.6,11.8,51.6,11.8c0.2-0.1,0.4-0.1,0.7-0.2c0,0.4,0.1,0.8,0.1,1.1c-0.1,0-0.1,0-0.2,0.1c-0.3,0.3-0.7,0.5-1,0.8
		C51.4,12.9,51.5,12.4,51.6,11.8z M53.6,12.6c0,0.1-0.1,0.2-0.1,0.3c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.6,0-1.2-0.1-1.8c0.2-0.1,0.4-0.3,0.6-0.4C53.5,11.4,53.6,12,53.6,12.6z M52.7,13.9
		c0.2-0.2,0.5-0.4,0.7-0.6c-0.2,0.4-0.5,0.8-0.8,1.2C52.6,14.3,52.6,14.1,52.7,13.9z M52.3,13.3C52.3,13.3,52.3,13.3,52.3,13.3
		c0,0.2,0,0.2-0.1,0.3c-0.5,0.4-1.1,0.8-1.8,1.1c0.1-0.1,0.2-0.3,0.3-0.4C51.3,14,51.8,13.7,52.3,13.3z M49.7,15.6
		c0.8-0.3,1.6-0.7,2.3-1.2c-0.2,0.5-0.5,0.9-0.8,1.2c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c-0.5,0.1-0.9,0.1-1.4,0.1
		C49.6,15.7,49.6,15.7,49.7,15.6z M53.7,13.7c0,0.2,0,0.5,0,0.7c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2,0.2-0.5,0.3-0.8,0.5
		C52.9,14.9,53.3,14.3,53.7,13.7z M54.2,13.6c0-0.3,0-0.6,0-0.9c0.3-0.7,0.5-1.4,0.5-2.1c0-0.1-0.1-0.3-0.2-0.3
		c-0.1,0-0.3,0.1-0.3,0.2c0,0.3-0.1,0.6-0.1,0.9c-0.1-0.3-0.1-0.7-0.3-1c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.2-0.2,0.3-0.4
		c0.3,0.5,0.5,1.1,0.6,1.6C54.9,12,54.6,12.9,54.2,13.6z M55.5,8.9c0,0.2,0,0.4,0,0.6c0,0.2,0,0.5-0.1,0.7c-0.1-0.4-0.3-0.7-0.5-1
		C55,9,55.2,8.7,55.3,8.5C55.4,8.6,55.4,8.8,55.5,8.9z M43.5,9.3C43.4,8.1,44,6.9,45,6c1.8-1.6,5-2.1,7.2-1.4c1.4,0.5,2.7,1.5,3.1,3
		c0,0,0,0.1-0.1,0.1c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3c0.2,0.1,0.3,0.3,0.5,0.4
		c-0.1,0.2-0.3,0.4-0.4,0.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.1,0.2,0.2,0.3
		c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.1c-0.1,0.1-0.2,0.2-0.1,0.3
		c0.1,0.2,0.2,0.4,0.2,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c0-0.2-0.1-0.4-0.1-0.6c0-0.1-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.2-0.2,0.3
		c0,0.3,0.1,0.5,0.1,0.8c-0.2,0.1-0.4,0.2-0.6,0.2c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.3
		c0.1,1.1-0.2,2.2-0.8,3.1c-0.5,0.3-1,0.5-1.6,0.6c0.2-0.2,0.3-0.4,0.4-0.7c0.5-0.3,1-0.6,1.4-1c0.1-0.1,0.1-0.3,0-0.4
		c-0.1-0.1-0.3-0.1-0.4,0c-0.2,0.2-0.4,0.4-0.6,0.5c0.2-0.5,0.4-1.1,0.5-1.7c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.3,0.1-0.3,0.2
		c-0.1,0.8-0.4,1.6-0.9,2.3c-0.3,0.1-0.6,0.2-0.8,0.3c0.4-0.5,0.6-1.1,0.7-1.7c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.3,0.1-0.3,0.2
		c-0.1,0.7-0.5,1.4-1,2c-0.3,0-0.7,0.1-1,0c0.6-0.5,1.2-1.2,1.5-1.9c0.1-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.3,0-0.3,0.1
		c-0.1,0.3-0.3,0.5-0.4,0.7c-0.4,0-0.7-0.1-1-0.2c0.2-0.2,0.4-0.5,0.5-0.8c0-0.1,0-0.3-0.2-0.3c-0.1,0-0.3,0-0.3,0.2
		c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.3,0.1-0.3,0.2
		c0,0.1,0,0.1-0.1,0.2c-0.1-0.1-0.1-0.1-0.2-0.1C43.8,10.8,43.5,10.1,43.5,9.3z M46,16.7c0.4-0.2,0.8-0.3,1.2-0.5
		c1.7,0.8,4.1,0.7,5.5,0.1c1.7,0.6,3.2,1.7,4.2,3.1c4,5.5,5.8,12.1,7.1,18.5c0,0.2,0.2,0.8-0.1,0.9c-0.1,0.1-0.4,0.1-0.6-0.1
		c-0.3-0.2-0.4-0.6-0.6-0.9l-6.5-13.3c0-0.1-0.1-0.2-0.2-0.2c-0.3-1.3-0.5-2.7-0.9-4c-0.1-0.3-0.3-0.4-0.6-0.4
		c-0.3,0.1-0.4,0.3-0.4,0.6c2,8.2,2.3,16.2,2.7,24.8l0.2,5.6l-0.3,0.4c-0.1-0.1-0.3,0-0.3,0.1c0,0.1,0,0.2,0,0.2l-0.1,0.2
		c-0.1,0.1-0.1,0.3,0.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1l0.2-0.2l0.1,0c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0-0.2,0.1-0.2,0.1
		c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1,0,0.3,0.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.2-0.3,0.3-0.4
		l0.4,0.2l0,0.2c0,0-0.1,0-0.1,0.1c-0.1,0.2-0.2,0.4-0.4,0.6l-0.1,0c-0.1-0.1-0.3,0-0.3,0.1c0,0.1,0,0.3,0.1,0.3l0,0
		c-0.1,0.3-0.2,0.5-0.3,0.8c0,0.1,0,0.3,0.2,0.3c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.2-0.2c0.1-0.3,0.2-0.5,0.3-0.8l0.3,0.1l0,1
		c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.3,0.2,0.3c0.1,0,0.3,0.1,0.4,0.1l-0.5,0.7c0,0-0.1,0-0.1-0.1
		c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3c0,0,0,0,0,0l-0.2,0.3c-0.1,0.1,0,0.3,0.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1
		l0.2-0.4c0.2,0.1,0.4,0.2,0.6,0.4c0,0.2-0.1,0.5-0.2,0.7c0,0,0,0.1-0.1,0.1L56,58.5c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3
		l0.9,0.4c-0.2,0.3-0.4,0.6-0.7,0.8l-0.5-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.2,0.2-0.3,0.4l-0.3-0.2c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3l0.3,0.2c-0.1,0.2-0.3,0.4-0.4,0.7
		c-0.6,0-1.2-0.3-1.6-0.7c0,0,0,0,0,0l0.3-0.4l0.8,0.5c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.3-0.1-0.3l-1.9-1.3
		c0.1-0.3,0.3-0.6,0.4-0.8l0.5,0.4c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4L53,57.1c0.1-0.2,0.2-0.4,0.4-0.6
		c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.2-0.3,0.4-0.4,0.6l-0.4-0.4c0.2-0.3,0.3-0.5,0.5-0.8
		c0.2,0.2,0.4,0.4,0.6,0.5c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4c-0.2-0.2-0.4-0.4-0.7-0.5
		c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.1-0.3-0.1-0.3c-0.1-0.1-0.3-0.1-0.3,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1-0.1-0.3-0.3-0.4-0.4
		l0.6-0.8c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.1-0.1-0.2-0.2l0.2-0.3
		c0.1-0.1,0.1-0.3-0.1-0.3c-0.1-0.1-0.3-0.1-0.4,0.1l-0.1,0.2c-0.2-0.2-0.4-0.3-0.6-0.5c0.1-0.2,0.3-0.4,0.4-0.7l0.4,0.4
		c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4l-0.4-0.4c0.1-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2,0-0.3,0l-0.6-0.6
		c0.1-0.3,0.3-0.5,0.4-0.8c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0-0.4
		c-0.1-0.2-0.3-0.3-0.4-0.5c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.2-0.2,0.3-0.4,0.5
		c-0.2-0.2-0.4-0.4-0.5-0.5c0.1-0.3,0.2-0.5,0.4-0.8c0.3,0.2,0.5,0.5,0.8,0.7c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.1-0.3,0-0.4c-0.3-0.2-0.5-0.5-0.8-0.8c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2-0.2-0.4-0.4-0.7-0.7c0-0.1,0-0.2,0-0.4c0.1,0,0.1-0.1,0.1-0.2c0-0.2,0.1-0.4,0.2-0.6l0.8,0.9
		c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4L52,44.6c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0-0.4
		c-0.1-0.1-0.3-0.1-0.4,0c0,0-0.1,0.1-0.1,0.1l-0.6-0.6c0-0.2,0-0.3-0.1-0.5c0.1-0.2,0.3-0.4,0.5-0.7c0.2,0.2,0.4,0.5,0.6,0.7
		c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3c-0.2-0.3-0.4-0.6-0.7-0.9c0.2-0.3,0.5-0.6,0.7-0.8
		c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0c-0.2,0.3-0.5,0.5-0.7,0.8c-0.2-0.2-0.5-0.5-0.7-0.7c0-0.2-0.1-0.5-0.1-0.7
		c0.1-0.2,0.2-0.4,0.3-0.6c0.3,0.3,0.6,0.7,0.9,1c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0-0.4
		c-0.3-0.4-0.7-0.8-1-1.2c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.2,0.3-0.3,0.4
		c-0.2-0.2-0.3-0.3-0.5-0.4c0-0.3-0.1-0.5-0.1-0.8c0-0.3-0.3-0.4-0.5-0.4c-0.3,0-0.5,0.2-0.5,0.5l-0.6,19c0,0.9-0.1,2-0.5,2.8
		c-0.4,0.7-1.2,1.3-1.9,1.1c-0.5-0.1-0.9-0.5-1.1-1.1c-0.2-0.6-0.3-1.2-0.3-1.9l-1.1-15c-0.2-3-0.4-6.2-0.3-9.3
		c0.2-3.5,0.8-7,1.5-10.4c0,0,0,0,0,0c0.2-0.2,0.2-0.4,0.1-0.6c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.3-0.1-0.5-0.4-0.6
		c-0.3-0.1-0.5,0.1-0.6,0.4c-0.1,0.5-0.2,1-0.3,1.6c-1.4,1.2-2.6,2.6-3.7,4l-0.1-0.2c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0-0.4
		c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.1-0.2,0.2l0-0.1c0-0.1-0.1-0.1-0.2-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.2,0-0.4
		c-0.1-0.1-0.2-0.1-0.4,0c-0.2,0.2-0.4,0.3-0.7,0.4c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.1,0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.1c0,0,0,0,0,0c0-0.1,0.1-0.3,0.1-0.4c0,0,0-0.1,0-0.1c0.8-1.3,1.6-2.5,2.6-3.7
		C43.4,18.4,44.7,17.3,46,16.7z M33.6,14.5c0.1,0.1,0.3,0.2,0.5,0.3c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1-0.1-0.3-0.1-0.4-0.2
		C33.3,15,33.4,14.8,33.6,14.5C33.6,14.5,33.6,14.5,33.6,14.5z M36.1,14.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3-0.1-0.6-0.2-0.9-0.3
		c0.2-0.2,0.3-0.4,0.4-0.6C35.6,14,35.9,14.1,36.1,14.2z M37.1,11.9c-0.1,0-0.3,0.1-0.3,0.2c0,0.1-0.1,0.2-0.1,0.3
		c-0.3-0.1-0.6-0.2-0.8-0.4c0.2-0.2,0.3-0.4,0.5-0.6c0.6,0.3,1.3,0.5,2.1,0.5c-0.1,0.2-0.2,0.5-0.3,0.8c-0.3,0-0.7-0.1-1-0.1
		c0-0.1,0.1-0.2,0.1-0.3C37.3,12.1,37.2,12,37.1,11.9z M36.9,9.9c0.2,0.1,0.4,0.3,0.6,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.1,0.3,0.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.1,0.5,0.1,0.7,0.2
		c0,0.3-0.1,0.5-0.1,0.8c-0.7,0-1.3-0.2-2-0.5C36.7,10.6,36.8,10.3,36.9,9.9z M38.7,9.2c0,0.3,0,0.6,0,0.9c-0.2,0-0.4-0.1-0.5-0.1
		c0.1-0.3,0.2-0.5,0.3-0.8C38.6,9.2,38.6,9.2,38.7,9.2z M38.6,8.7C38.6,8.7,38.6,8.7,38.6,8.7C38.6,8.7,38.6,8.7,38.6,8.7
		C38.6,8.7,38.6,8.7,38.6,8.7z M38,13.2C38,13.2,38,13.2,38,13.2c-0.1,0.3-0.2,0.5-0.3,0.7c-0.3,0-0.6,0-0.9-0.1
		c0.1-0.3,0.2-0.5,0.3-0.8C37.4,13.1,37.7,13.2,38,13.2z M37.4,14.4c-0.1,0.2-0.2,0.4-0.4,0.5c0,0,0,0,0,0c-0.2,0-0.4,0-0.7-0.1
		c0.1-0.2,0.2-0.4,0.3-0.6C36.9,14.4,37.1,14.4,37.4,14.4z M35.5,15.3c-0.1,0.2-0.2,0.4-0.4,0.6c-0.4-0.1-0.8-0.1-1.2-0.3
		c0.2-0.2,0.4-0.4,0.6-0.6C34.9,15.1,35.2,15.2,35.5,15.3z M33,15.7c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.3-0.1-0.5-0.1
		c0.2-0.1,0.3-0.2,0.5-0.4C32.8,15.6,32.9,15.7,33,15.7z M31.7,16.3c0.1,0.1,0.2,0.1,0.4,0.2c-0.2,0-0.4-0.1-0.6-0.1
		C31.5,16.3,31.6,16.3,31.7,16.3C31.6,16.2,31.6,16.3,31.7,16.3z M33.3,16.2C33.3,16.2,33.3,16.2,33.3,16.2c0.1-0.1,0.2-0.2,0.3-0.3
		c0.4,0.1,0.8,0.2,1.3,0.3c0,0,0,0.1-0.1,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.4,0-0.7,0.1-1,0.1C33.3,16.5,33.3,16.4,33.3,16.2z
		 M36.1,15.4c0.2,0,0.4,0,0.5,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.5,0.3-0.8,0.4C35.8,15.8,35.9,15.6,36.1,15.4z M27.6,12.6
		c-1.2-2.1-1.4-4.1,0.6-5.9c1.8-1.6,5-2.1,7.2-1.4c1.2,0.4,2.2,1.1,2.7,2.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0.3,0,0.6,0,0.9
		c-0.2-0.1-0.3-0.2-0.4-0.4C37.7,8,37.5,8,37.4,8.1c-0.1,0.1-0.1,0.2-0.1,0.3C37.5,8.7,37.8,8.9,38,9c-0.1,0.3-0.2,0.6-0.3,0.8
		c-0.3-0.1-0.6-0.3-0.8-0.5c-0.1-0.1-0.3-0.1-0.4,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.2c0,0.4-0.1,0.8-0.3,1.2
		c-0.3-0.2-0.5-0.3-0.8-0.6c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.3,0.2,0.6,0.4,0.9,0.6c-0.1,0.2-0.3,0.4-0.5,0.6
		c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.2,0.5,0.3c-0.2,0.1-0.3,0.2-0.5,0.3
		c-0.1,0.1-0.2,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.3-0.1,0.5-0.3,0.7-0.5c0.3,0.2,0.7,0.4,1.1,0.5
		c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2-0.1-0.5-0.1-0.7-0.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2,0-0.3,0.1c-0.5-0.3-1-0.6-1.4-0.9
		c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.4,0.4,0.9,0.7,1.5,1c-0.1,0.2-0.3,0.4-0.4,0.6c-0.6-0.3-1.1-0.6-1.6-1
		c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.2,0.2,0.5,0.4,0.7,0.6c0,0,0,0,0,0c-0.2,0.3-0.4,0.5-0.7,0.7
		c-0.3-0.2-0.6-0.3-0.9-0.6c-0.1-0.1-0.3-0.1-0.3,0.1c-0.1,0.1-0.1,0.3,0.1,0.3c0.2,0.2,0.5,0.3,0.8,0.5c-0.2,0.1-0.3,0.2-0.5,0.3
		c-0.3-0.2-0.5-0.4-0.7-0.6c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.2,0.2,0.4,0.3,0.6,0.5C31,16,30.9,16,30.8,16
		c-0.5-0.2-0.9-0.5-1.4-1C28.8,14.5,28.2,13.7,27.6,12.6z M27.2,18c1.1-0.3,2.1-0.7,3.2-1c1.8,0.8,4.4,0.6,5.9-0.2
		c1.5,0.3,2.9,0.8,4.5,1.5c0,0.2,0.1,0.3,0.1,0.5c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.3,0c-0.1,0.1-0.1,0.3,0,0.4
		c0.2,0.2,0.4,0.3,0.7,0.3c0,0.1,0,0.1,0,0.2l0,0c-0.4-0.1-0.7-0.2-1-0.4c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3
		c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3
		c0.2,0.1,0.5,0.2,0.7,0.3c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0l-0.5-0.2c-0.1-0.1-0.1-0.1-0.2-0.1l-0.1,0c-0.1,0-0.3,0-0.3,0.2
		c0,0.1,0,0.3,0.2,0.3l0.1,0.1c0,0.1,0,0.2,0,0.3c-0.2,0-0.3,0-0.5,0.1l-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0.1-0.1,0.2-0.1,0.3
		l0.1,0.1c-0.5,0.2-0.5,0.6,0.4,6.2c0.1,0.9,0.3,1.7,0.3,1.9c0.1,1.9,0.1,3.9,0.1,5.8c0,1.3,0,2.6,0,3.9l0.1,3.2
		c0.1,4.2,0.2,8.5,0.2,12.8c0,0.3,0,0.5,0,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.3,0.1
		c-0.1,0.1-0.1,0.3,0.1,0.3c0,0,0.1,0,0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.3,0,0.4c0,0,0.1,0.1,0.2,0.1
		c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0.1,0c-0.1,0.4-0.2,0.7-0.3,1.1c-0.3-0.3-0.5-0.5-0.9-0.7
		c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3c0.3,0.2,0.6,0.4,0.9,0.7c-0.1,0.2-0.3,0.4-0.4,0.6c0-0.1-0.1-0.2-0.2-0.3
		c0,0-0.1,0-0.1,0c0.1-0.1,0.1-0.2,0.2-0.4c0-0.1,0-0.3-0.1-0.3c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0,0-0.1,0
		c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3c0,0,0.1,0,0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.2,0-0.4-0.1
		c-0.4-0.1-0.8-0.4-1-0.8l0.4-0.5c0.2,0.1,0.3,0.3,0.5,0.4c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4
		c-0.2-0.2-0.4-0.3-0.6-0.5c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0c-0.3-0.2-0.5-0.4-0.8-0.6c0-0.2,0-0.4-0.1-0.6l0.3-0.4l0.8,0.8
		c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4L34.9,55c0-0.3,0-0.5,0-0.8l0.2-0.3c0.1,0.2,0.3,0.3,0.3,0.5
		c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3c-0.1-0.2-0.3-0.5-0.5-0.7l0.2-0.3c0.1-0.1,0-0.3-0.1-0.3
		c-0.1-0.1-0.3,0-0.3,0.1L35,53.1C35,53,34.9,53,34.9,52.9c0-0.4,0-0.7,0-1.1l0-0.1c0.2,0.2,0.3,0.4,0.5,0.6c0,0.1,0.1,0.1,0.2,0.1
		c0.1,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.1-0.4c-0.2-0.3-0.4-0.5-0.6-0.7l0.4-0.7c0.1-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.3,0-0.3,0.1
		l-0.3,0.5c0,0,0,0,0,0l0-0.3c0-0.4,0-0.9-0.1-1.3c0.1,0.2,0.3,0.3,0.4,0.5c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
		c0.1-0.1,0.1-0.2,0.1-0.3c-0.2-0.3-0.4-0.5-0.6-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.1-0.2,0-0.3c0,0,0,0,0,0c0,0,0.1,0,0.1,0
		c0.1-0.1,0.2-0.2,0.1-0.3c-0.1-0.3-0.3-0.6-0.5-0.8l0.2-0.3c0.1-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.3,0-0.3,0.1l-0.1,0.2
		c0,0-0.1-0.1-0.1-0.1c0-0.6-0.1-1.1-0.2-1.6l0.1-0.2c0.2,0.3,0.4,0.6,0.5,0.9c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0
		c0.1,0,0.2-0.2,0.2-0.3c-0.1-0.5-0.4-0.9-0.7-1.2l0.4-0.7c0.1-0.1,0-0.3-0.1-0.3c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2l-0.4-0.5
		c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.3l-0.4-0.5
		c-0.1-0.3-0.1-0.6-0.2-0.9l0.3-0.3c0.3,0.3,0.6,0.6,0.9,0.9c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4
		c-0.3-0.3-0.6-0.6-1-0.9l0.3-0.4c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0l-0.3,0.4c-0.2-0.2-0.4-0.3-0.7-0.5c0,0,0,0,0-0.1
		c-0.1-0.4-0.3-1-0.8-1c-0.3,0-0.6,0.1-0.8,0.5c-0.3,0.5-0.3,1.1-0.4,1.6l0,0.3C31,41.3,31,42.4,31,43.6c0,4,0.1,8.1,0.3,12.1
		c0,0.9,0.1,1.8-0.2,2.5c-0.2,0.8-0.8,1.4-1.5,1.6c-0.8,0.2-1.7-0.5-2.1-1.2c-0.5-0.8-0.6-1.8-0.7-2.9c-0.3-4-0.4-8.1-0.5-12.1
		c-0.1-7.1-0.3-15.1,2.5-22c0.1-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.5,0.1-1,0.2-1.5,0.2c-0.1,0-0.1,0-0.2,0l0.3-0.2
		c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.2-0.2-0.3-0.2c0,0,0-0.1,0-0.1
		c-0.1-0.1-0.2-0.2-0.3-0.1l-0.3,0.2c0-0.1-0.1-0.2-0.3-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.1-0.3
		c-0.1-0.1-0.2-0.2-0.3-0.1c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.1-0.3
		c-0.1-0.1-0.2-0.2-0.3-0.1c-0.2,0.1-0.3,0.1-0.5,0.2l0-0.2c0-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.2-0.2,0.3l0,0.2c-0.1,0-0.2,0-0.3,0
		c-0.2-0.2-0.3-0.4-0.5-0.5C24.2,18.8,25.7,18.5,27.2,18z M13.6,16.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2
		c-0.2,0-0.5-0.1-0.7-0.2C13.5,16.2,13.5,16.1,13.6,16.1z M14.5,15.5c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,0.1-0.3,0.2-0.4,0.3
		c-0.2-0.1-0.4-0.2-0.5-0.3c0,0,0,0,0,0C14.2,15.7,14.4,15.6,14.5,15.5z M15.2,16.3c0.1-0.1,0.2-0.1,0.3-0.2
		c0.2,0.1,0.5,0.2,0.7,0.4c-0.3,0-0.7,0.1-1,0c0,0,0,0,0,0C15.2,16.4,15.2,16.4,15.2,16.3z M16.2,15.4c0.2,0.1,0.4,0.2,0.5,0.3
		l-0.2,0.3c-0.2-0.1-0.4-0.2-0.7-0.3C16,15.6,16.1,15.5,16.2,15.4z M17,16.3C17,16.3,17,16.3,17,16.3C17,16.3,17,16.3,17,16.3
		l0.2-0.3c0.1,0.1,0.3,0.1,0.4,0.1C17.5,16.2,17.2,16.3,17,16.3z M18.3,15.8c0,0-0.1-0.1-0.2-0.1c-0.2,0-0.4-0.1-0.6-0.1l0.3-0.4
		c0.2,0.1,0.5,0.3,0.8,0.4c0,0-0.1,0.1-0.1,0.1C18.5,15.7,18.4,15.7,18.3,15.8z M18.4,13.4c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0
		c0.1,0,0.2-0.1,0.2-0.1c0.3,0.2,0.6,0.3,0.9,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.8-0.1-1.5-0.4-2.1-0.9c0,0,0.1,0,0.1,0
		c0.1-0.1,0.3-0.3,0.4-0.5c0.1,0.1,0.3,0.2,0.4,0.3C18.4,13.2,18.4,13.3,18.4,13.4z M20.7,8.5c0,0.1,0.1,0.3,0.1,0.4
		c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.2,0.3-0.5,0.4-0.8C20.7,8.5,20.7,8.5,20.7,8.5z M20.9,10
		c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4-0.1,0.7c-0.2-0.1-0.3-0.1-0.5-0.2C20.5,10.5,20.7,10.2,20.9,10C20.8,10,20.9,10,20.9,10z
		 M20.4,12.6c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.2-0.1-0.4-0.2-0.5-0.3C19.8,13,20.1,12.8,20.4,12.6
		C20.3,12.6,20.3,12.6,20.4,12.6z M9.8,12.6c-1.2-2.1-1.4-4.1,0.6-5.9c1.8-1.6,5-2.1,7.2-1.4c1.2,0.4,2.2,1.1,2.7,2.1
		c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4
		c0.2,0.2,0.4,0.3,0.7,0.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.4,0c-0.1,0.1-0.1,0.2,0,0.4
		c0.1,0.2,0.3,0.3,0.4,0.4c-0.1,0.1,0,0.2,0.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1,0.1,0.2,0.1,0.4,0.2
		c-0.1,0.2-0.3,0.4-0.4,0.6c-0.3-0.2-0.6-0.4-0.9-0.7c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.3,0.3,0.6,0.5,0.9,0.7
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.3,0,0.4c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2,0.1,0.5,0.3,0.7,0.4c0,0,0,0.1,0,0.1c-0.2,0.2-0.3,0.4-0.5,0.5c-0.6-0.3-1.2-0.8-1.7-1.3c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.1,0.3,0,0.4c0.5,0.5,1.1,0.9,1.7,1.3c-0.2,0.2-0.5,0.4-0.8,0.5c-0.2-0.1-0.4-0.3-0.6-0.5c0.1-0.1,0.2-0.2,0.3-0.4
		c0.1-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.1,0.3,0,0.4c0,0,0.1,0.1,0.1,0.1c-0.1,0.2-0.3,0.3-0.4,0.4c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1-0.1
		c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.7,0.7,1.6,1.2,2.6,1.4c-0.1,0.2-0.2,0.3-0.4,0.5c-0.3-0.1-0.6-0.2-0.8-0.4
		l0.1-0.1c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0l-0.1,0.1c-0.4-0.2-0.8-0.5-1.1-0.8c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.1,0.3,0,0.4c0.3,0.3,0.7,0.6,1.1,0.9l-0.4,0.5c-0.2-0.1-0.3-0.2-0.5-0.3c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0-0.4
		c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0.1-0.1,0.2-0.1,0.3
		c0.1,0.2,0.3,0.4,0.5,0.5c-0.1,0.1-0.3,0.2-0.4,0.4c-0.3-0.2-0.6-0.4-0.9-0.6c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1,0,0.2,0,0.2
		c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.1-0.2-0.3-0.4-0.4l0,0c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.2,0.2,0.3,0.3
		c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4-0.2-0.8-0.5-1.2-0.9C10.9,14.5,10.4,13.7,9.8,12.6z M6.4,20.2c-0.2-0.7,0.4-1.6,1.6-1.8
		c0.3,0,0.6,0,0.9,0c-0.9,0.9-1.2,1.5-1.8,2.3C6.8,20.7,6.5,20.4,6.4,20.2z M1.1,37.3c1-3.7,2.5-7.5,4.6-12c1.6-3.4,3.4-6.8,6.8-8.5
		c1.8,0.9,4.1,0.6,5.4,0.2c0.1,0,0.2,0.1,0.3,0.1c3.5,1.5,5.8,4.7,7.8,7.7c0,0.1,0,0.3-0.1,0.4c-0.1-0.1-0.3-0.1-0.3,0
		c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2,0,0.2c0,0,0,0-0.1,0c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3
		c0.1,0.1,0.2,0.2,0.4,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.1,0.3
		c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.3-0.1-0.3,0.1c0,0,0,0,0,0c-1.3-1.8-2.8-3.5-4.6-4.8c-0.2-0.6-0.4-1.1-0.6-1.7c-0.1-0.3-0.4-0.4-0.7-0.3
		c-0.3,0.1-0.4,0.4-0.3,0.7c0.9,2.1,1.2,4.3,1.4,6.4c0.6,5.5,0.4,11.1,0.3,16.5c0,1.7-0.1,3.3-0.1,5c-0.1,8.4,0,9.4-0.4,10
		c-0.5,0.8-1.9,0.9-2.9-0.3c-0.7-0.9-0.9-2-1-3.3c-0.6-5-1.2-11.5-1.4-18.2c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1L14,38.5
		l-0.3-0.2c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3l0.3,0.1c-0.2,0.1-0.3,0.3-0.5,0.4l0,0c-0.1,0-0.3,0-0.3,0.1
		c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.1,0.3,0,0.4c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0c0,0.1,0,0.2,0.1,0.3
		l0.5,0.3l-0.2,0.5l-0.2-0.1c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3l0.3,0.2l-0.2,0.4c-0.1,0.1,0,0.3,0.1,0.3
		c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1l0.2-0.3l0.1,0c0,0.2-0.1,0.4-0.1,0.6l-0.1,0.1l-0.7-0.4c-0.1-0.1-0.3,0-0.3,0.1
		s0,0.3,0.1,0.3l0.6,0.3L12.5,44l-0.2-0.1c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3l0.2,0.1c0,0.1,0,0.2,0.1,0.2
		c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0l0.4,0.2c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2-0.1-0.4-0.2-0.7-0.3c-0.1,0-0.3,0-0.3,0.1
		c0,0.1,0,0.3,0.1,0.3c0.2,0.1,0.4,0.2,0.5,0.2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3
		c0.2,0.1,0.5,0.2,0.7,0.4c-0.1,0.1-0.2,0.2-0.3,0.4l-0.3-0.2c-0.1-0.1-0.3-0.1-0.3,0.1c-0.1,0.1-0.1,0.3,0.1,0.3l0.3,0.2
		c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0,0,0,0c-0.1-0.1-0.2,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3l0.7,0.4c-0.1,0.2-0.2,0.3-0.3,0.5
		c0,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0-0.1,0.1
		c-0.1,0.1-0.1,0.3,0,0.4c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.2,0.1,0.3,0.2,0.5,0.3L12,51.1l-0.5-0.4
		c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3l0.6,0.4L11.5,52l-0.3-0.2c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3l0.4,0.3
		c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0l0.5,0.3l-0.3,0.5c-0.3-0.1-0.6-0.3-0.8-0.5c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.1,0.3,0,0.4c0.3,0.2,0.6,0.4,0.9,0.6l-0.3,0.6l-0.7-0.4c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3L11,55l-0.3,0.6
		c0,0.1,0,0.1,0,0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.3,0.1c-0.1,0.1-0.1,0.3,0.1,0.3c0.4,0.3,0.9,0.6,1.3,0.8l-0.2,0.4
		c-0.4-0.2-0.7-0.4-1.1-0.5c-0.1-0.1-0.3,0-0.3,0.1c-0.1,0.1,0,0.3,0.1,0.3c0.3,0.2,0.7,0.3,1,0.5l-0.1,0.2c-0.1,0.1,0,0.3,0.1,0.3
		c-0.1,0-0.2,0-0.3,0.1c0,0,0,0.1,0,0.1c-0.3-0.2-0.7-0.4-1-0.7c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4
		c0.3,0.3,0.7,0.5,1,0.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.4,0c-0.1,0.1-0.1,0.2,0,0.4
		c0.1,0.1,0.2,0.2,0.4,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6-1-0.7-2.3-0.7-3.4C8,45.4,7.8,33.2,11.3,21.7
		c0.1-0.3-0.1-0.5-0.3-0.6c-0.3-0.1-0.5,0.1-0.6,0.3c-0.3,1.1-0.6,2.1-0.9,3.2c0,0-0.1,0.1-0.1,0.1C6.6,28.9,4,33.3,1.7,37.9
		c-0.2,0.5-0.4,0.5-0.4,0.5c-0.1,0-0.2-0.1-0.2-0.3C1,37.9,1,37.6,1.1,37.3z"/>
</svg>;

export default PeopleIconSvg;