import React from 'react';

const CardPicSvg = () => 
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65">
        <g>
            <path className="st0" d="M46.3,1.1c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4-0.1-0.5,0.1c-0.5,0.4-1,0.8-1.4,1.3c-0.3,0.3-0.7,0.6-1,0.9
		c-0.8-0.9-1.8-1.7-2.6-2.3l-0.1-0.1c-0.2-0.2-0.5-0.1-0.6,0C39.1,1,38.6,1.5,38,2c-0.4,0.4-0.9,0.7-1.3,1.1
		c-0.8-1.1-1.6-2.1-2.4-2.8c-0.2-0.1-0.4-0.2-0.6-0.1c-0.7,0.4-1.3,0.8-2,1.2c-0.5,0.3-1.1,0.6-1.7,1c-0.4-0.3-0.8-0.7-1.2-1
		c-0.5-0.4-1-0.8-1.4-1.2C27.3,0,27.1,0,26.9,0.1c-0.6,0.4-1.3,0.8-2,1.2c-0.6,0.3-1.1,0.7-1.7,1l-2.4-1.9c-0.2-0.1-0.4-0.1-0.6,0
		c-0.6,0.4-1.2,0.8-1.7,1.2c-0.5,0.4-0.9,0.7-1.4,1c-0.8-0.2-2.2-0.8-2.9-1.9c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0.1
		c-0.5,0.5-1,1-1.6,1.5c-0.4,0.4-0.8,0.8-1.2,1.2C10.3,2.8,9.9,2.4,9.4,2C8.8,1.6,8.3,1.1,7.7,0.6C7.5,0.5,7.3,0.5,7.1,0.6
		C7,0.6,6.9,0.8,6.8,1C5.6,18.8,5.4,37,6.4,55c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.3,0.1,0.5,0c0.7-0.5,1.4-0.9,2-1.5
		c0.3,0.2,0.6,0.4,0.9,0.6c0.4,0.3,0.8,0.6,1.2,0.9c0.2,0.1,0.4,0.1,0.6,0c0.6-0.5,1.2-1,1.9-1.6c0.8,0.6,1.6,1.1,2.3,1.6
		c0.2,0.1,0.4,0.1,0.5,0c0.4-0.3,0.8-0.6,1.1-0.9c0.3-0.2,0.5-0.4,0.8-0.6c0.1,0.1,0.2,0.1,0.3,0.2c0.1,3.1,0.2,6.3,0.4,9.9
		c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.3,0.1,0.5,0c0.7-0.5,1.3-0.9,2-1.5c0.3,0.2,0.6,0.4,0.8,0.6c0.4,0.3,0.8,0.6,1.3,0.9
		c0.2,0.1,0.4,0.1,0.6,0c0.6-0.4,1.2-0.9,1.9-1.6c0.8,0.6,1.5,1.1,2.3,1.6c0.2,0.1,0.4,0.1,0.6,0c0.4-0.3,0.8-0.6,1.1-0.9
		c0.3-0.2,0.5-0.4,0.8-0.6c0.3,0.2,0.6,0.5,1,0.7c0.4,0.3,0.8,0.6,1.3,0.9c0.2,0.1,0.5,0.1,0.6-0.1c0.8-0.8,1.6-1.4,2.4-2
		c1.1,0.6,2,1.2,2.9,2c0.2,0.2,0.5,0.2,0.7,0c0.9-0.8,1.7-1.5,2.7-2.1c0.4,0.3,0.8,0.6,1.2,0.9c0.5,0.4,1,0.7,1.5,1.1
		c0.2,0.2,0.5,0.1,0.7-0.1c0.6-0.7,1.2-1.3,1.8-2c0.3,0.2,0.6,0.5,0.9,0.7c0.4,0.3,0.8,0.7,1.2,1c0.2,0.2,0.5,0.1,0.7-0.1
		c0.5-0.6,1.1-1.2,1.9-1.6c0.5,0.4,0.9,0.7,1.4,1.1c0.6,0.4,1.1,0.8,1.6,1.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0
		c0.2-0.1,0.3-0.2,0.3-0.4c1.9-15.6,2.6-32.3,2.4-54.4c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4-0.1-0.5,0.1c-0.5,0.4-0.9,0.8-1.4,1.2
		c-0.4,0.3-0.7,0.7-1.1,1c-0.8-0.9-1.7-1.6-2.6-2.3l-0.1-0.1c-0.2-0.2-0.5-0.1-0.6,0C52,10,51.5,10.5,50.9,11
		c-0.5,0.4-0.9,0.8-1.3,1.2C48.8,11,48,10,47.2,9.3c-0.2-0.2-0.4-0.2-0.6-0.1c-0.1,0-0.1,0.1-0.2,0.1C46.4,6.7,46.4,4,46.3,1.1z
		 M18.2,52.9c-0.4,0.3-0.7,0.6-1.1,0.9c-0.3,0.2-0.5,0.4-0.8,0.6c-0.7-0.5-1.5-1-2.3-1.6c-0.2-0.2-0.5-0.1-0.7,0
		c-0.7,0.7-1.3,1.2-1.8,1.6c-0.3-0.2-0.6-0.4-0.9-0.7c-0.4-0.3-0.8-0.6-1.2-0.9c-0.2-0.1-0.4-0.1-0.6,0c-0.5,0.4-1,0.8-1.5,1.2
		C6.5,36.7,6.6,19.2,7.8,2c0.3,0.3,0.7,0.5,1,0.8c0.6,0.5,1.1,0.9,1.7,1.4c0.2,0.1,0.5,0.1,0.6,0c0.5-0.5,1-1,1.6-1.5
		c0.4-0.4,0.8-0.7,1.1-1.1c1,1.2,2.6,1.8,3.3,2c0.1,0,0.3,0,0.4-0.1c0.6-0.4,1.2-0.8,1.7-1.2c0.4-0.4,0.9-0.7,1.3-1l2.4,1.9
		c0.2,0.1,0.4,0.1,0.6,0c0.6-0.4,1.3-0.8,2-1.2c0.6-0.3,1.1-0.7,1.7-1c0.4,0.3,0.8,0.7,1.2,1c0.5,0.4,1,0.8,1.4,1.2
		c0.2,0.1,0.4,0.2,0.6,0.1c0.7-0.4,1.3-0.8,2-1.2c0.5-0.3,1.1-0.6,1.6-1C34.7,2,35.5,3,36.2,4.1c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.3,0,0.4-0.1c0.5-0.5,1.1-1,1.7-1.5c0.5-0.4,1-0.8,1.4-1.2c0.9,0.7,1.9,1.5,2.6,2.4c0.1,0.1,0.2,0.2,0.3,0.2
		c0.1,0,0.3,0,0.4-0.1c0.5-0.4,1-0.8,1.4-1.3c0.2-0.2,0.4-0.4,0.6-0.5c0,2.8,0,5.4,0,8c0,0,0,0,0,0c0,5.3-0.1,10.3-0.2,15
		c0,0.1,0,0.3,0,0.4c0,0.8-0.1,1.7-0.1,2.5c0,0.2,0,0.3,0,0.5c-0.1,3.5-0.3,6.8-0.5,10.1c0,0.2,0,0.4,0,0.6c0,0.7-0.1,1.4-0.1,2
		c0,0.2,0,0.5-0.1,0.7c0,0.7-0.1,1.3-0.2,2c0,0.2,0,0.5-0.1,0.7c-0.1,0.7-0.1,1.3-0.2,2c0,0.2,0,0.4-0.1,0.7c-0.1,0.7-0.1,1.3-0.2,2
		c0,0.1,0,0.3,0,0.4c-0.2,1.6-0.3,3.1-0.5,4.6c-0.3-0.2-0.6-0.5-1-0.7c-0.6-0.4-1.1-0.8-1.6-1.3c-0.1-0.1-0.4-0.1-0.5-0.1
		c-0.9,0.4-1.7,1-2.3,1.6c-0.3-0.2-0.6-0.5-0.9-0.7c-0.4-0.3-0.8-0.6-1.2-0.9c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1
		c0,0-0.1,0.1-0.1,0.1c-0.6,0.6-1.2,1.1-1.7,1.8c0,0-0.1,0.1-0.1,0.1c-0.4-0.3-0.7-0.6-1.1-0.9c-0.5-0.4-1-0.7-1.5-1.1c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.2-0.1-0.4-0.2-0.6,0c-1,0.6-1.9,1.3-2.8,2.1c-0.9-0.8-1.9-1.4-3-2c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0
		c-0.1,0-0.2,0-0.3,0.1c-0.2,0.2-0.5,0.3-0.7,0.5c-0.6,0.4-1.2,0.9-1.7,1.4c0,0-0.1,0.1-0.1,0.1c-0.1-0.1-0.3-0.2-0.4-0.3
		c-0.3-0.2-0.7-0.5-1-0.7c0,0,0,0,0,0c-0.3-0.2-0.5-0.4-0.8-0.6C18.6,52.8,18.4,52.8,18.2,52.9z M46.5,10.4c0.1-0.1,0.2-0.1,0.3-0.2
		c0.8,0.7,1.5,1.7,2.2,2.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4-0.1c0.5-0.5,1.1-1,1.8-1.5c0.5-0.4,0.9-0.8,1.3-1.1
		c0.9,0.7,1.9,1.5,2.6,2.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.4-0.1c0.5-0.5,1-0.9,1.5-1.3c0.2-0.2,0.3-0.3,0.5-0.5
		c0.2,21.1-0.5,37.3-2.3,52.3c-0.3-0.2-0.6-0.5-1-0.7c-0.6-0.4-1.1-0.8-1.6-1.3c-0.1-0.1-0.4-0.1-0.5-0.1c-0.9,0.4-1.7,1-2.3,1.6
		c-0.3-0.2-0.6-0.4-0.8-0.7c-0.4-0.3-0.8-0.7-1.2-1c-0.2-0.1-0.5-0.1-0.6,0c-0.7,0.6-1.3,1.3-1.9,2c-0.4-0.3-0.7-0.6-1.1-0.9
		c-0.5-0.4-1-0.7-1.5-1.1c-0.2-0.1-0.4-0.2-0.6,0c-1,0.6-1.9,1.3-2.8,2.1c-0.9-0.8-1.9-1.4-3-2c-0.1,0-0.1-0.1-0.2-0.1
		c-0.1,0-0.2,0-0.3,0.1c-0.9,0.6-1.7,1.2-2.5,1.9c-0.3-0.2-0.6-0.5-0.9-0.7c-0.4-0.3-0.8-0.6-1.3-0.9c-0.2-0.1-0.4-0.1-0.6,0
		c-0.4,0.3-0.8,0.6-1.1,0.9c-0.3,0.2-0.5,0.4-0.8,0.6c-0.8-0.5-1.5-1-2.3-1.6c-0.2-0.2-0.5-0.1-0.7,0c-0.7,0.7-1.3,1.2-1.9,1.6
		c-0.3-0.2-0.6-0.5-0.9-0.7c-0.4-0.3-0.8-0.6-1.2-0.9c-0.2-0.1-0.4-0.1-0.6,0c-0.5,0.4-1,0.8-1.5,1.2c-0.1-2.9-0.3-5.7-0.3-8.2
		c0.3,0.2,0.6,0.5,0.9,0.7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.4,0.3,0.7,0.6,1,1c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
		c0.1-0.1,0.1-0.2,0.1-0.3c-0.3-0.4-0.6-0.7-1-1c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.3,0.5-0.5c0.4,0.3,0.8,0.7,0.9,1.3
		c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3c-0.2-0.6-0.5-1.1-1-1.5c0,0,0,0,0,0c0.2-0.1,0.4-0.3,0.6-0.4
		c0.4,0.4,0.7,0.9,1,1.4c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3c-0.3-0.5-0.6-1-1-1.5c0,0,0,0,0.1-0.1
		c1.1,0.6,2,1.2,2.9,2c0.1,0.1,0.3,0.2,0.5,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.2l1,1.2c0,0.1,0.1,0.1,0.2,0.1
		c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0-0.4l-1-1.2c0.1-0.1,0.2-0.2,0.4-0.3c0.4,0.3,0.7,0.7,0.8,1.2c0,0.1,0.1,0.2,0.2,0.2
		c0,0,0,0,0,0c0.1,0,0.2-0.2,0.2-0.3c-0.1-0.6-0.4-1.1-0.9-1.4c0.2-0.1,0.3-0.2,0.5-0.3c0.6,0.6,1.1,1.3,1.4,2
		c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3c-0.4-0.8-0.9-1.5-1.5-2.1c0,0,0,0,0.1,0c0.2,0.3,0.5,0.5,0.7,0.7
		c0.3,0.2,0.5,0.4,0.7,0.7c0.4,0.4,0.6,1,0.8,1.5c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3
		c-0.2-0.5-0.5-1.1-0.9-1.6c-0.2-0.3-0.5-0.5-0.8-0.7c-0.2-0.2-0.4-0.3-0.5-0.5c0.3,0.3,0.7,0.5,1,0.8c0.5,0.4,1,0.7,1.5,1.1
		c0.2,0.2,0.5,0.1,0.7-0.1c0,0,0,0,0,0c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.3
		c-0.1-0.4-0.2-0.9-0.4-1.3c0.2-0.2,0.4-0.5,0.6-0.7l0.6,1.3c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3
		L34.9,54c0.2-0.2,0.3-0.3,0.5-0.5c0.2,0.2,0.5,0.4,0.7,0.6l0.5,1.5c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3
		l-0.2-0.8c0.2,0.2,0.4,0.4,0.7,0.5c0.2,0.1,0.4,0.1,0.6,0c0.1,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.5,0.5,1,0.6,1.6
		c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.1,0,0.2-0.2,0.2-0.3c-0.1-0.7-0.4-1.3-0.8-1.8c0.2-0.2,0.4-0.3,0.6-0.5l0.8,1.5
		c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3l-0.8-1.6c0.1,0,0.2-0.1,0.2-0.1c0.5,0.4,0.9,0.7,1.4,1.1
		c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.5,0.3,0.9,0.4,1.3c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3
		c-0.1-0.2-0.2-0.4-0.2-0.6c0.2,0.2,0.4,0.3,0.7,0.5c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0.1-0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0
		c0.1,0,0.2-0.2,0.2-0.3c-0.2-0.6-0.4-1.1-0.6-1.7c0.1-0.5,0.1-1,0.2-1.6c0,0,0,0,0,0c0.3,0.6,0.5,1.3,0.6,2c0,0.1,0.1,0.2,0.2,0.2
		c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.3c-0.1-0.9-0.4-1.8-0.9-2.5c0-0.1-0.1-0.1-0.2-0.1c0-0.4,0.1-0.8,0.1-1.3c0,0,0-0.1,0-0.1
		l0.6,1.9c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3l-0.9-3c0-0.4,0.1-0.8,0.1-1.3c0-0.1,0-0.1,0-0.2
		c0.3,0.4,0.5,0.8,0.7,1.2c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3c-0.3-0.6-0.6-1.2-1.1-1.7c0,0,0,0,0-0.1
		c0-0.5,0.1-1,0.1-1.5c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3
		c-0.1-0.6-0.3-1.1-0.6-1.7c0,0,0,0,0,0c0-0.5,0.1-1,0.1-1.5c0.1,0.3,0.2,0.7,0.3,1c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0
		c0.1,0,0.2-0.2,0.2-0.3c-0.2-0.7-0.5-1.4-0.7-2c0,0,0,0,0-0.1c0.1-0.7,0.1-1.3,0.1-2c0-0.1,0-0.2,0-0.2c0-0.2,0-0.4,0-0.6
		c0.2,0.4,0.3,0.9,0.3,1.3c0,0.1,0.1,0.3,0.2,0.3c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2c0-0.8-0.2-1.6-0.7-2.2c0-0.7,0.1-1.3,0.1-2
		c0.2,0.7,0.4,1.3,0.5,2c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.1,0,0.2-0.2,0.2-0.3c-0.2-1.1-0.5-2.1-0.9-3.2c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.1,0-0.2c0-0.4,0-0.7,0.1-1.1c0.1,0.2,0.1,0.3,0.2,0.5c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3
		c-0.1-0.5-0.3-1-0.6-1.5c0-0.2,0-0.5,0-0.7c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.1,0,0.2-0.2,0.2-0.3
		c-0.1-0.5-0.3-1-0.5-1.5c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0
		c0.1,0,0.2-0.2,0.2-0.3c-0.1-0.4-0.2-0.8-0.5-1.1c0-0.2,0-0.4,0-0.5c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.2
		c0-0.4-0.1-0.8-0.3-1.2c0-0.3,0-0.6,0-1c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.3c0-0.5-0.2-1-0.4-1.4
		c0-0.2,0-0.5,0-0.7c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.2,0.1-0.3l-0.4-1.1c0-0.3,0-0.6,0-0.9c0,0,0.1,0,0.1,0
		c0.1,0,0.3-0.1,0.3-0.3c0-0.4-0.1-0.7-0.3-1.1c0-0.3,0-0.7,0-1c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3
		c-0.1-0.4-0.2-0.7-0.4-1c0-0.5,0-0.9,0-1.4c0.1,0.2,0.2,0.4,0.4,0.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.1-0.2,0-0.4c-0.3-0.4-0.6-0.8-0.8-1.2c0-0.5,0-1,0-1.5c0.4,0.4,0.7,0.9,0.9,1.3c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
		c0.1-0.1,0.2-0.2,0.1-0.3c-0.3-0.7-0.8-1.3-1.3-1.8c0,0,0,0-0.1,0c0-0.5,0-1,0-1.5L46.5,10.4z"/>
            <path className="st0" d="M12,31.7c6.2,0.5,12.5,0.6,18.7,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5c-6.2,0.1-12.5-0.1-18.7-0.5
		c-0.3,0-0.5,0.2-0.5,0.5C11.5,31.5,11.7,31.7,12,31.7z"/>
            <path className="st0" d="M33.4,32l3.8,0.1c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5L33.4,31c-0.3,0-0.5,0.2-0.5,0.5
		C32.9,31.7,33.1,32,33.4,32z"/>
            <path className="st0" d="M11.6,35.8c5.9,0.7,12.1,0.8,18.5,0.3c0.3,0,0.5-0.3,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5
		c-6.1,0.5-12.2,0.4-18.3-0.3c-0.3,0-0.5,0.2-0.6,0.4C11.1,35.5,11.3,35.8,11.6,35.8z"/>
            <path className="st0" d="M32.6,35.9l4.1,0.1c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5l-4.1-0.1c-0.2,0-0.5,0.2-0.5,0.5
		C32.1,35.6,32.3,35.9,32.6,35.9z"/>
            <path className="st0" d="M31.1,39.1c-6.3,0.4-12.6,0.4-18.9,0.2c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c6.2,0.2,12.6,0.2,19-0.2
		c0.3,0,0.5-0.3,0.5-0.5C31.6,39.3,31.3,39.1,31.1,39.1z"/>
            <path className="st0" d="M33.5,39.6c0,0.3,0.2,0.5,0.5,0.5l4.3,0.1c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5l-4.3-0.1
		C33.8,39.1,33.6,39.3,33.5,39.6z"/>
            <path className="st0" d="M31.4,44.1c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0c-6.4,0.1-12.8,0.2-19.1,0.1c0,0,0,0,0,0c-0.3,0-0.5,0.2-0.5,0.5
		c0,0.3,0.2,0.5,0.5,0.5c6.4,0.1,12.8,0,19.2-0.1C31.2,44.6,31.4,44.4,31.4,44.1z"/>
            <path className="st0" d="M38.1,43.7l-4.2-0.1c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5l4.2,0.1c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5
		C38.6,43.9,38.4,43.7,38.1,43.7z"/>
            <path className="st0" d="M24.3,22.7c0.5,0.2,1.1,0.3,1.6,0.4l-0.2,1.9c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5
		l0.1-1.9c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3,0c1.8-0.1,3.2-0.8,3.7-2c0.6-1.4-0.2-2.9-1.1-3.6c-1-0.8-2.3-1.2-3.4-1.4l-0.3-0.1
		c-1-0.2-2-0.4-2.8-0.9c-0.7-0.5-1.4-1.3-1.3-2.2c0.2-1,1.3-1.6,1.8-1.8c1.4-0.6,2.8-0.7,4-0.4c1.3,0.4,2.4,1.4,2.7,2.6
		c0.1,0.3,0.3,0.4,0.6,0.4c0.3-0.1,0.4-0.3,0.4-0.6c-0.4-1.5-1.7-2.8-3.4-3.3c-0.4-0.1-0.9-0.2-1.4-0.2c0-0.5,0.1-1,0.1-1.5
		c0.1-0.3-0.1-0.5-0.4-0.6c-0.3-0.1-0.5,0.1-0.6,0.4c-0.1,0.6-0.2,1.1-0.2,1.7c-0.8,0.1-1.5,0.3-2.3,0.6c-0.8,0.3-2.2,1.1-2.4,2.6
		c-0.2,1.4,0.8,2.6,1.8,3.2c1,0.6,2.1,0.8,3.1,1.1l0.3,0.1c1,0.2,2.1,0.5,3,1.2c0.7,0.6,1.2,1.6,0.9,2.5c-0.4,1-1.7,1.4-2.8,1.4
		c-1.1,0-2.2-0.2-3.2-0.5c-2.1-0.8-2.5-2.1-2.1-3c0.1-0.2,0.3-0.5,0.6-0.7c0.3-0.1,0.4-0.4,0.3-0.7c-0.1-0.3-0.4-0.4-0.7-0.3
		c-0.5,0.2-0.9,0.6-1.2,1.2C20.9,20.3,22.3,21.9,24.3,22.7z"/>
        </g>
    </svg>;


export default CardPicSvg;