import { setCustData } from './customers';
import jsonwebtoken from 'jsonwebtoken';
import {
    removeAPIConfig,
    LOCAL_STORAGE_PATH_API_CONFIG,
    LOCAL_STORAGE_PATH_API_NAME,
    LOCAL_STORAGE_PATH_API,
} from './firebase';

export const MODAL_ON_OFF = 'MODAL_ON_OFF';
export const SET_ERROR = 'SET_ERROR';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_INFO = 'SET_INFO';
export const SET_WARNING = 'SET_WARNING';
export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const LOCAL_STORAGE_PATH_FIREBASE_TOKEN = 'dsa:firebaseToken';
export const LOCAL_STORAGE_PATH_API_TOKEN = 'dsa:apiToken';
export const LOCAL_STORAGE_PATH_CUTOMER_DATA = 'dsa:customerData';
export const SET_PROGRESS_BAR = 'SET_PROGRESS_BAR';
export const SET_SIGNUP_REQUIRED = 'SET_SIGNUP_REQUIRED';
export const SET_CONFIGURATION_LOADED = 'SET_CONFIGURATION_LOADED';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';

export const setModalVisibility = (isOn) => {
    return { type: MODAL_ON_OFF, isOn: isOn };
};

export const setError = (error) => {
    return { type: SET_ERROR, error: error };
};

export const setMessage = (message) => {
    return { type: SET_MESSAGE, message: message };
};

export const setInfo = (info) => {
    return { type: SET_INFO, info: info };
};
export const setWarning = (warn) => {
    return { type: SET_WARNING, warn: warn };
};

export const showProgressBar = (inProgress) => {
    return { type: SET_PROGRESS_BAR, inProgress: inProgress };
};

export const setSignupRequired = (isRequired) => {
    return { type: SET_SIGNUP_REQUIRED, isRequired: isRequired };
};

export const setActiveMenu = (menuId) => {
    return { type: SET_ACTIVE_MENU, menuId: menuId };
};

export const loggedInToApi = (apiResponse) => {
    return (dispatch) => {
        dispatch(setCustData(apiResponse));
        dispatch(checkAuthenticated());
    };
};
export const setConfigurationLoaded = (isLoaded) => {
    return {
        type: SET_CONFIGURATION_LOADED,
        isLoaded: isLoaded,
    };
};
export const setSignedIn = (
    firebaseSignedIn,
    apiSignedIn,
    firebaseToken,
    apiToken
) => {
    return {
        type: SET_SIGNED_IN,
        firebaseSignedIn: firebaseSignedIn,
        apiSignedIn: apiSignedIn,
        firebaseToken: firebaseToken,
        apiToken: apiToken,
    };
};

export const checkAuthenticated = () => {
    return (dispatch, getState) => {
        const state = getState();
        const authPublicKeys = state.settingsStore.authPublicKeys;
        //check if firebase token from storage is valid
        const firebaseToken = localStorage.getItem(
            LOCAL_STORAGE_PATH_FIREBASE_TOKEN
        );


        const chkFTokenRes = checkToken(firebaseToken, 'all', authPublicKeys);
        if (chkFTokenRes.isCorrect) {
            //check if API token is valid
            const apiToken = localStorage.getItem(LOCAL_STORAGE_PATH_API_TOKEN);

            const chkAPITokenRes = checkToken(apiToken, 'expiry', authPublicKeys);
            if (chkAPITokenRes.isCorrect) {
                //get customerData from storage
                const customerData = localStorage.getItem(
                    LOCAL_STORAGE_PATH_CUTOMER_DATA
                );
                dispatch(setCustData(JSON.parse(customerData)));
                //save to redux
                dispatch(setSignedIn(true, true, firebaseToken, apiToken));
            } else {
                //no valid api token
                dispatch(setSignedIn(true, false, firebaseToken, null));
            }
        } else {
            //no valid firebase token
            //console.log(
            // 	'dispatch(setSignedIn(false, false, null, null))',
            // 	dispatch(setSignedIn(false, false, null, null))
            // );
            dispatch(setSignedIn(false, false, null, null));
        }
    };
};

export const logout = () => {
    return (dispatch) => {
        localStorage.removeItem(LOCAL_STORAGE_PATH_FIREBASE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_PATH_API_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_PATH_CUTOMER_DATA);
        localStorage.removeItem(LOCAL_STORAGE_PATH_API_CONFIG);
        localStorage.removeItem(LOCAL_STORAGE_PATH_API_NAME);
        localStorage.removeItem(LOCAL_STORAGE_PATH_API);
        dispatch(setSignedIn(false, false, null, null));
        dispatch(setSignupRequired(false));
        dispatch(setConfigurationLoaded(false));
        dispatch(removeAPIConfig());
        dispatch(setActiveMenu(0));
    };
};

export const checkToken = (token, scope, authPublicKeys) => {

    if (!scope)
        return { isCorrect: false, message: 'Token check scope not defined.' };
    if (token) {
        try {
            let decoded = jsonwebtoken.decode(token, { complete: true });
            if (scope === 'all') {
                const publicKey = authPublicKeys.filter(
                    (x) => x.key === decoded.header.kid
                )[0];

                if (!publicKey)
                    return { isCorrect: false, message: 'Firebase public key not found' };

                jsonwebtoken.verify(token, publicKey.value, {
                    algorithms: decoded.header.alg,
                });
                return { isCorrect: true, message: 'Token ok' };
            }
            if (scope === 'expiry') {
                var now = new Date();
                var expD = new Date(decoded.payload.exp * 1000);

                if (!decoded.payload.exp)
                    return {
                        isCorrect: false,
                        message: 'Błędny token. Brak daty ważności.',
                    };
                if (now > expD)
                    return { isCorrect: false, message: 'Błędny token. Token expired.' };
                else return { isCorrect: true, message: 'Token ok.' };
            }
        } catch (err) {
            return { isCorrect: false, message: 'Błędny token. ' + err.message };
        }
    }
    return { isCorrect: false, message: 'Brak tokenu' };
};
