import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { APP_ENV_PARAM_DEV, APP_STAGE_PARAM_UAT } from '../utils/environment';

var testConfigJson = require('./testConfigJson.json');

export const firebaseConfigUAT = {
    apiKey: 'AIzaSyBhWbo-y0cRxJsIr95JQc_B4WAZdUp8AoQ',
    authDomain: 'dance-school-app-e9d27.firebaseapp.com',
    databaseURL: 'https://dance-school-app-e9d27.firebaseio.com',
    projectId: 'dance-school-app-e9d27',
    storageBucket: 'dance-school-app-e9d27.appspot.com',
    messagingSenderId: '271613283475',
    appId: '1:271613283475:web:1a411b5ba36a99d013d166',
    measurementId: 'G-3RF0Q8VXB7',
};

export const firebaseConfigPROD = {
    apiKey: 'AIzaSyCBF-8DaN4hb2x3S5nnL6jJkM9ncfEGRyo',
    authDomain: 'szkolatanca.app',
    databaseURL: 'https://dance-school-app-prod.firebaseio.com',
    projectId: 'dance-school-app-prod',
    storageBucket: 'dance-school-app-prod.appspot.com',
    messagingSenderId: '841797983639',
    appId: '1:841797983639:web:3b264990c9b8942e5738d2',
    measurementId: 'G-6NNQPQSEJ5',
};

export const appInstance = initializeApp(
    // eslint-disable-next-line no-undef    
    (process.env.NODE_ENV === APP_ENV_PARAM_DEV || process.env.REACT_APP_STAGE === APP_STAGE_PARAM_UAT)
        ? firebaseConfigUAT
        : firebaseConfigPROD
);

export const analytics = getAnalytics(appInstance);

export const remoteConfig = getRemoteConfig(appInstance);
remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
};

remoteConfig.defaultConfig = {
    apiUrl: 'http://localhost:5003/api/',
    login_settings: {
        login_email_enabled: true,
        login_gmail_enabled: true,
    },
};

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {    
    remoteConfig.defaultConfig = testConfigJson;
}

export const auth = getAuth(appInstance);
export const database = getDatabase(appInstance);
export const firestore = getFirestore();
export const storage = getStorage(appInstance);
