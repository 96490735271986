import axios from 'axios';
import { LOCAL_STORAGE_PATH_API } from '../store/actions/firebase';
import * as errorMsgs from '../errors/errorMessages';

const getLrnAPIClientConfig = (apiToken, baseURL) => {
    return new Promise((resolve) => {
        const auth = apiToken ? 'Bearer ' + apiToken : 'none';
        const config = {
            baseURL,
            timeout: 50000,
            headers: {
                'Content-Type': 'application/json',
                Authorization: auth,
            },
        };
        resolve(config);
    });
};

export const httpLrnAPIClient = (apiToken) => {
    const apiURL = localStorage.getItem(LOCAL_STORAGE_PATH_API);
    if (!apiURL) throw errorMsgs.apiUrlError;
    console.log('apiURL', apiURL);
    return new Promise((resolve) => {
        getLrnAPIClientConfig(apiToken, apiURL).then((config) => {
            resolve(axios.create(config));
        });
    });
};
