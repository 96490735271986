import React, { Fragment, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navigation.css';
import MenuItem from '../MenuItem/MenuItem';

const Navigation = (props) => {
	
    const { pathname } = useLocation();
    /* eslint-disable */
	const [activeItem, setActiveItem] = useState('/');

	const navigationItems = [
		{ id: 1, order: 1, name: 'dashboard', path: '/', desc: 'Pulpit' },
		{ id: 2, order: 2, name: 'calendar', path: '/calendar', desc: 'Kalendarz' },
		{ id: 3, order: 3, name: 'myevents', path: '/myevents', desc: 'Moje' },
		{ id: 4, order: 4, name: 'newschedules', path: '/newschedules', desc: 'Dodaj' },
		{ id: 5, order: 5, name: 'payments', path: '/regularpayments', desc: 'Płatności',},
		{ id: 6, order: 6, name: 'account', path: '/account', desc: 'Konto',},
	];
	const subNavigationItems = [
		{ id: 61, order: 61, name: 'myachievements', path: '/myachievements'},
		{ id: 62, order: 62, name: 'userprofile', path: '/feedback' },
		{ id: 63, order: 62, name: 'feedback', path: '/myevents' },
		{ id: 51, order: 51, name: 'payments', path: '/regularpayments'},
		{ id: 52, order: 52, name: 'deposits', path: '/deposits' },
	];

	if (props.socialModuleEnabled) {
		navigationItems.push({
			id: 7,
			name: 'socialsearch',
			path: '/socialsearch',
			desc: 'Kontakty',
		});
	}

	const getNameFromPath = (inputPath) => { 		
		let serachPathname = inputPath;
		const childPage = inputPath.split('/');		
		if(childPage.length >1) serachPathname = childPage[1];		
		return serachPathname;
	 }
	const isActive = (itemPath) => {		
		let isActive = false;
		const currPathName = getNameFromPath(pathname);
		const itemPathName = getNameFromPath(itemPath)		
		if (itemPathName === currPathName) {
			isActive = true;			
		}
		return isActive;
	};

	const activeItemIndex = () => {
		const serachPathname = getNameFromPath(pathname);
		let item = navigationItems.find((e) => e.name === serachPathname);
		if(!item) item = subNavigationItems.find((e) => e.name === serachPathname);
		
		return item ? item.order : 0;
	};

	const onMenuSelect = (path) => {
		setActiveItem(path);
	};

	return (
		<Fragment>
			<div className='navigation-container app-max-size' fixed='bottom' bg=''>
				<div
					className={
						activeItemIndex() === 1
							? 'navigation-side border-curved-lr'
							: 'navigation-side border-curved-left'
					}
				/>
				{navigationItems.map((element) => (
					<NavLink key={element.name} to={element.path}>
						<MenuItem
							id={element.name}
							item={element.name}
							text={element.desc}
							active={isActive(element.path)}
							onClick={() => onMenuSelect(element.path)}
							positionFromActive={activeItemIndex() - element.order}
						/>
					</NavLink>
				))}
				<div
					className={
						activeItemIndex() === navigationItems.length
							? 'navigation-side border-curved-lr'
							: 'navigation-side border-curved-right'
					}
				/>{' '}
			</div>
			<div className='navigation-container-footer'></div>
		</Fragment>
	);
};

export default Navigation;
