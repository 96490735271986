import { SET_NEWSMESSAGES} from '../actions/messaging';

export const initialState = {
    newsmessages : []
};

const messagingReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_NEWSMESSAGES:
        return {
            ...state,
            newsmessages: action.messages,
        };
    default:
        return state;
    }
};

export default messagingReducer;
