import React from 'react';
import SelectSchool from '../../components/SelectSchool/SelectSchool';

const SelectSchoolPage = () => {
    return (
        <div>
            <SelectSchool></SelectSchool>
        </div>
    );
};

export default SelectSchoolPage;
