// Libs
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
//For Mobile devices
//import { GoogleSignin } from '@react-native-google-signin/google-signin';

// Store
import { showProgressBar, setError, setConfigurationLoaded } from '../store/actions/ui';

// Firebase
import { remoteConfig } from '../firebase/Firebase';
import { fetchAndActivate, getAll } from 'firebase/remote-config';

export default () => {
    const dispatch = useDispatch();
    const configurationLoaded = useSelector((state) => state.uiStore.configurationLoaded);
    const setConfig = () => {

        dispatch(showProgressBar(true)
        );

        fetchAndActivate(remoteConfig)
            .then(() => {
                dispatch(showProgressBar(false));
                //dispatch(setMessage({message:'Pomyślnie załadowano konfigurację'}));
                // eslint-disable-next-line no-undef
                if (process.env.NODE_ENV === 'development') {
                    const config = getAll(remoteConfig);
                    console.log('remoteConfig', config);
                    const confJson = JSON.stringify(config);
                    localStorage.setItem('dev:firebase:config', confJson);
                }
                dispatch(setConfigurationLoaded(true));
            })
            .catch(() => {
                dispatch(
                    setError({
                        message: 'Błąd podczas pobierania konfiguracji. Odśwież aplikację.',
                    })
                );
                dispatch(showProgressBar(false));
                dispatch(setConfigurationLoaded(false));
            });
    };
    useEffect(() => {
        if (!configurationLoaded) {
            setConfig();
        }
        // Init google signin
        // GoogleSignin.configure({
        //     webClientId: '271613283475-vatgspijg4tet1i4b1re90u1f7c3139b.apps.googleusercontent.com',
        // }); 
    }, [configurationLoaded]);
  
};
