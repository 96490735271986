import React from 'react';

import './MenuItem.css';
import { DashboardIcon, CalendarIcon } from '../../Icons/Icons';
import { useSelector } from 'react-redux';
import ManIconSvg from '../../Icons/resources/svg/ManIconSvg';
import WomanIconSvg from '../../Icons/resources/svg/WomanIconSvg';
import EventsIconSvg from '../../Icons/resources/svg/EventsIconSvg';
import SheetWithPinSvg from '../../Icons/resources/svg/SheetWithPinSvg';
import PeopleIconSvg from '../../Icons/resources/svg/PeopleIconSvg';
import CardPicSvg from '../../Icons/resources/svg/CardPicSvg';

const MenuItem = (props) => {
    const userData = useSelector((state) => state.customerStore.customerData);

    const content = (item) => {
        switch (item) {
        case 'dashboard':
            return <DashboardIcon />;
        case 'calendar':
            return <CalendarIcon />;
        case 'myevents':
            return <EventsIconSvg />;
        case 'newschedules':
            return <SheetWithPinSvg />;
        case 'account':
            return userData.gender === 'M' ? <ManIconSvg /> : <WomanIconSvg />;
        case 'socialsearch':
            return <PeopleIconSvg />;
        case 'payments':
            return <CardPicSvg />;

        default:
            break;
        }
    };
    const menuItem = () => {
        let className = '';
        if (props.active === true) {
            className = 'menu-item active';
        } else {
            className = 'menu-item';
        }

        switch (props.positionFromActive) {
        case 0:
            className += ' border-curved-lr';
            break;
        case 1:
            className += ' border-curved-right ';
            break;
        case -1:
            className += ' border-curved-left';
            break;
        default:
            break;
        }

        return (
            <div className='menu-item-background'>
                <div className={className} onClick={props.onClick}>
                    <div className='menu-item-icon'>{content(props.item)}</div>
                    <div className='menu-item-text'>{props.text}</div>
                </div>
            </div>
        );
    };

    return (
        <div id={props.id} className='menu-item-container'>{menuItem()}</div>

    // <div className='menu-item-icon'>{props.icon}</div>
    // <div className='menu-item-text'>{props.title}</div>
    );
};

export default MenuItem;
