import React, { Fragment } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import './ProgressBarModal.css';

const ProgressBarModal = () => {

    const inProgress = useSelector(state => state.uiStore.inProgress);

    return (

        inProgress ? <Fragment>
            <Backdrop show={true} />
            <div className='progress-bar-container'>
                {/* <ProgressBar animated now={90} /> */}
                <Spinner animation='border' role='status' style={{ height: '50px', width: '50px'}} />
            </div>
        </Fragment> : null
    );
};


export default ProgressBarModal;