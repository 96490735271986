import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logout } from '../../store/actions/ui';
import styles from './SelectedEndpointTag.module.css';

const SelectedEndpointTag = ({ name }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onClickSchoolName = () => {
        dispatch(logout());
        navigate('/');
    };

    return (
        <div id='selectedEndpointTag' className={styles.container}>
            <div className={styles.label} onClick={onClickSchoolName} >
                {name}
            </div>
        </div>
    );
};

export default SelectedEndpointTag;
