import React, { Fragment, useEffect, useState } from 'react';
import './Cookies.css';

const Cookies = () => {
    const [displayConsent, setdisplayConsent] = useState(false);

    const getCookiesConsent = () => {
        let cookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith('cookiesConsent='));
        if (cookie) {
            return cookie.split('=')[1];
        } else {
            return false;
        }
    };
    const checkConsent = () => {
        let consent = getCookiesConsent();
        if (!consent || consent === false) {
            setdisplayConsent(true);
        } else {
            setdisplayConsent(false);
        }
    };
    const setCookiesConsent = () => {
        document.cookie =
			'cookiesConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
        setdisplayConsent(false);
    };
    // const resetOnce = () => {
    // 	document.cookie =
    // 		'cookiesConsent=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    // };

    useEffect(() => {
        checkConsent();
    }, [displayConsent]);

    return (
        <Fragment>
            {displayConsent ? (
                <div className='cookies-container app-max-size'>
                    <div className='cookies-header'>Używamy pliki cookie</div>
                    <div className='cookies-text'>
						Używamy plików cookie i innych technologii śledzenia, aby poprawić
						jakość przeglądania naszej witryny, wyświetlać spersonalizowane
						treści, analizować ruch w naszej witrynie i wiedzieć, skąd pochodzą
						nasi użytkownicy. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, 
						że będą one zamieszczane w Państwa urządzeniu końcowym. 
						Jeśli nie wyrażają Państwo zgody, uprzejmie prosimy o dokonanie stosownych zmian 
						w ustawieniach przeglądarki internetowej.
                    </div>
                    <div className='cookies-buttons'>
                        <button className='btn main confirm' onClick={setCookiesConsent}>
							Zgadzam się
                        </button>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default Cookies;
