import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { remoteConfig } from '../firebase/Firebase';
import { setError } from '../store/actions/ui';
import { getNumber } from '@firebase/remote-config';

const FacebookSDK = () => {
    const dispatch = useDispatch();
    const facebookAppId = getNumber(remoteConfig, 'facebook_app_id');

    //const [fbSdkLoaded, setFbSdkLoaded] = useState(false);

    useEffect(() => {
        if (facebookAppId && facebookAppId > 0) {
            setupFacebook();
        } else {
            dispatch(
                setError({
                    message:
						'Błąd podczas pobierania danych z Facebook (brak nr aplikacji [' +
						facebookAppId +
						'])',
                })
            );
        }

        // return () => {
        //     //removeFbRoot();
        // };
    }, []);

    const setupFacebook = () => {
        if (document.getElementById('facebook-jssdk')) {
            //setFbSdkLoaded(true);
            return;
        }    
        setFbAsyncInit();
        loadSdkAsynchronously();
        createFbRoot();
    };

    const setFbAsyncInit = () => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: facebookAppId,
                autoLogAppEvents: true,
                xfbml: false,
                version: 'v9.0',
            });
            //setFbSdkLoaded(true);
        };
    };

    const loadSdkAsynchronously = () => {
        ((d, s, id) => {
            const element = d.getElementsByTagName(s)[0];
            const fjs = element;
            let js = element;
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    };

    const createFbRoot = () => {
        let fbRoot = document.getElementById('fb-root');
        if (!fbRoot) {
            fbRoot = document.createElement('div');
            fbRoot.id = 'fb-root';
            document.body.appendChild(fbRoot);			
        }
    };

    // const removeFbRoot = () => {
    // 	let fbRoot = document.getElementById('fb-root');
    // 	if (fbRoot) {
    // 		document.body.removeChild(fbRoot);
    // 	}
    // };

    return null;
};

export default FacebookSDK;
