import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './SelectSchool.css';
import { remoteConfig } from '../../firebase/Firebase';
import { saveAPIConfig } from '../../store/actions/firebase';
import { getValue } from 'firebase/remote-config';

const SelectSchool = () => {
    const dispatch = useDispatch();

    const [renderUI, setRenderUI] = useState(false);

    let apiUrls = [];
    let domainIdMap = [];
    const domain = window.location.host;

    try {
        apiUrls = JSON.parse(getValue(remoteConfig, 'api_urls').asString());
        domainIdMap = JSON.parse(
            getValue(remoteConfig, 'domain_id_map').asString()
        );
    } catch {
        apiUrls = [];
        domainIdMap = [];
    }

    const onSelectSchool = (configRec) => {
        console.log('configRec', configRec);
        dispatch(saveAPIConfig(configRec));
    };

    const getSubDomain = () => {
        const subDomain = domain.split('.');
        return subDomain[0];
    };

    const selectUrlsConfig = (configId) => {
        let conf = [...apiUrls];
        conf = conf.filter((x) => x.id === configId);
        if(conf.length === 1)
            return conf[0];
        else 
            return null;
    };

    const findConfigUsingSubDomain = () => {
        return selectUrlsConfig(getSubDomain());
    };

    const getIdUsingDomainIdMapping = () => {
        let idMap = [...domainIdMap];
        idMap = idMap.filter((x) => x.domain === domain);
        if(idMap.length === 1)
            return selectUrlsConfig(idMap[0].configId);
        else
            return null;
    };

    useEffect(() => {
        if (apiUrls && apiUrls.length === 1) {
            onSelectSchool(apiUrls[0]);
        } else {
            const domainConf = findConfigUsingSubDomain();
            if (domainConf) {
                onSelectSchool(domainConf);
            } else {
                const idConf = getIdUsingDomainIdMapping();
                if (idConf) onSelectSchool(idConf);
                else setRenderUI(true);
            }
        }
    }, []);

    return (
        <>
            {renderUI ? (
                <div>
                    <div className="select-school-header">Wybierz szkołę</div>
                    <div className="select-school">
                        {apiUrls.map((item) => (
                            <button
                                className="btn btn-primary submit-button tile"
                                onClick={() => onSelectSchool(item)}
                                key={item.kid}
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default SelectSchool;
