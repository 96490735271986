import { getBoolean, getString, getNumber, getValue } from 'firebase/remote-config';
import { remoteConfig } from '../../firebase/Firebase';
import { appUrlModifier } from '../../utils/helpers/urlHelper';

export const SET_SELECTED_API_CONFIG = 'SET_SELECTED_API_CONFIG';
export const SET_SELECTED_API_CONFIG_ID = 'SET_SELECTED_API_CONFIG_ID';
export const LOCAL_STORAGE_PATH_API = 'dsa:api:url';
export const LOCAL_STORAGE_PATH_API_NAME = 'dsa:api:appName';
export const LOCAL_STORAGE_PATH_API_CONFIG = 'dsa:api:config';
export const LOCAL_STORAGE_PATH_API_ID = 'dsa:api:id';

export const setSelectedAPIConfig = (configRec) => {
    return { type: SET_SELECTED_API_CONFIG, configRec };
};

export const setSelectedAPIConfigId = (configRecId) => {
    return { type: SET_SELECTED_API_CONFIG_ID, configRecId };
};

export const saveAPIConfig = (configRec) => {
    return (dispatch) => {
        const backendAPIUrl = appUrlModifier(configRec.apiUrl);
        localStorage.setItem(LOCAL_STORAGE_PATH_API_ID, configRec.id);
        localStorage.setItem(LOCAL_STORAGE_PATH_API, backendAPIUrl);
        localStorage.setItem(LOCAL_STORAGE_PATH_API_NAME, configRec.name);
        localStorage.setItem(LOCAL_STORAGE_PATH_API_CONFIG, JSON.stringify(configRec));
        dispatch(setSelectedAPIConfig(configRec));
        dispatch(setSelectedAPIConfigId(configRec.id));
    };
};

export const removeAPIConfig = () => {
    return (dispatch) => {
        localStorage.removeItem(LOCAL_STORAGE_PATH_API_ID);
        localStorage.removeItem(LOCAL_STORAGE_PATH_API);
        localStorage.removeItem(LOCAL_STORAGE_PATH_API_NAME);
        localStorage.removeItem(LOCAL_STORAGE_PATH_API_CONFIG);
        dispatch(setSelectedAPIConfig(null));
        dispatch(setSelectedAPIConfigId(null));
    };
};
const remoteConfigReader = (paramName, type, state) => {
    const selectedAPIConfig = state.firebaseStore.selectedAPIConfig;
    if (!selectedAPIConfig)
        return null;
    //throw errorMsgs.configurationIssue('SelectedAPIConfig');
    const paramNameWPrefix = selectedAPIConfig.id + '_' + paramName;
    switch (type) {
    case 'bool':
        //const val = getBoolean(remoteConfig, paramNameWPrefix);
        return getBoolean(remoteConfig, paramNameWPrefix);
    case 'string':
        return getString(remoteConfig, paramNameWPrefix);
    case 'number':
        return getNumber(remoteConfig, paramNameWPrefix);
    default:
        return getValue(remoteConfig, paramNameWPrefix);
    }
};

export const remoteConfigReader_getBoolean = (paramName) => {
    return (dispatch, getState) => { return remoteConfigReader(paramName, 'bool', getState());};
};
export const remoteConfigReader_getString = (paramName) => {
    return (dispatch, getState) => { return remoteConfigReader(paramName, 'string', getState());};
};
export const remoteConfigReader_getNumber = (paramName) => {
    return (dispatch, getState) => { return remoteConfigReader(paramName, 'number', getState());};
};
export const remoteConfigReader_getValue = (paramName) => {
    return (dispatch, getState) => { return remoteConfigReader(paramName, getState());};
};

