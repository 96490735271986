/* eslint-disable no-undef */
import React from 'react';
import './ThisIsTheEndPage.css';

const ThisIsTheEndPage = () => {
    return (
        <div className="container">            
            <div className='image' >
                <img src={process.env.PUBLIC_URL + '/goodbye_pic.webp'} alt="Goodbye"
                    width={300} height={300}
                />
            </div>  
            <div className='texts'>
                <div className='main-text color-primary'>To już koniec !!! :(</div>
                <div className='text-second color-primary'>Dziękuję z używanie tej aplikacji.</div>
                <div className='text-second color-primary'>Niestety ta aplikacja odchodzi do przeszłości.</div>
                <div className='text-third' >W razie pytań prosimy kontaktować się z: larosanegraww@gmail.com</div>
            </div>
                       
        </div>
    );
};

export default ThisIsTheEndPage;