import * as actions from '../actions/facebook';

export const initialState = {
    loginData: '',
    loggedIn: false,
    dataFetchError: false,
};

const facebookReducer = (state = initialState, action) => {
    switch (action.type) {
    case actions.SET_LOGIN_RESPONSE:
        return {
            ...state,
            loginData: action.resp,
        };
    case actions.SET_LOGGEDIN:
        return {
            ...state,
            loggedIn: action.loggedIn,
        };
    case actions.SET_DATA_FETCH_ERROR:
        return {
            ...state,
            dataFetchError: action.isError,
        };

    default:
        return state;
    }
};

export default facebookReducer;
