import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './Topbar.css';

const Topbar = () => {
    const activeMenuId = useSelector((state) => state.uiStore.activeMenuId);

    const myAccTopMenu = [
        { id: 1, desc: 'Dane', path: '/account' },
        { id: 2, desc: 'Osiągnięcia', path: '/myachievements' },
        { id: 3, desc: 'Ustawienia', path: '/userprofile' },
        { id: 4, desc: 'Feedback', path: '/feedback' },
    ];

    const socialTopMenu = [
        { id: 1, desc: 'Szukaj', path: '/socialsearch' },
        { id: 2, desc: 'Znajomi', path: '/socialcontacts' },
    ];

    const paymentsTopMenu = [
        { id: 1, desc: 'Regularne', path: '/regularpayments' },
        { id: 2, desc: 'Kaucje', path: '/deposits' },
    ];

    const renderItems = () => {
        let menuToRender = null;
        if (activeMenuId === 6) {
            menuToRender = myAccTopMenu;
        }
        if (activeMenuId === 7) {
            menuToRender = socialTopMenu;
        }
        if (activeMenuId === 5) {
            menuToRender = paymentsTopMenu;
        }
        const items = menuToRender ? (
            menuToRender.map((item) => (
                <NavLink key={item.id} to={item.path}>
                    {item.desc}
                </NavLink>
            ))
        ) : (
            <div className='topbar-nomenu'></div>
        );

        return items;
    };

    return (
        <div className='topbar-container app-max-size'>
            {renderItems()}
        </div>
    );
};

export default Topbar;
