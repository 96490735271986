import * as actions from '../actions/calendar';

export const initialState = {
    selectedDate: new Date()
};

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
    case actions.SET_SELECTED_DATE:
        return {
            ...state,
            selectedDate: action.date,
        };
    default:
        return state;
    }
};

export default calendarReducer;
