import * as actions from '../actions/events';

export const initialState = {
    events: [],
    proposals: [],
    signups: [],
    newSchedules:[],
    error: '',
    appMessage: '',
    isDataLoading: false,
    eventIdsBeingChanged: [99],
};

const entriesReducer = (state = initialState, action) => {
    switch (action.type) {
    case actions.EVENT_SET_ARRAY:
        return {
            ...state,
            events: action.events,
        };
    case actions.EVENT_SET_CHANGING:
        //TODO refactor
        // eslint-disable-next-line no-case-declarations
        let updatedArray = [...state.eventIdsBeingChanged];
        if (action.remove) {
            updatedArray = state.eventIdsBeingChanged.filter(
                (p) => p !== action.id
            );
        } else {
            updatedArray.push(action.id);
        }
        return {
            ...state,
            eventIdsBeingChanged: updatedArray,
        };
    case actions.SIGNUP_SET_ARRAY:
        return {
            ...state,
            signups: action.signups,
        };
    case actions.PROPOSALS_SET_ARRAY:
        return {
            ...state,
            proposals: action.proposals,
        };
    case actions.EVENT_SET_ELEMENT:
        //TODO refactor
        // eslint-disable-next-line no-case-declarations
        let newEvents = [...state.events];
        // eslint-disable-next-line no-case-declarations
        const eventIdx = state.events.findIndex((p) => p.Id === action.oldId);
        console.log('eventIdx', eventIdx);
        console.log('action.oldId', action.oldId);
        // eslint-disable-next-line no-case-declarations
        const newEvent = action.event;
        if (eventIdx > -1) {
            newEvents[eventIdx] = newEvent;
            return {
                ...state,
                events: newEvents,
            };
        } else {
            return state;
        }
    case actions.NEW_SCHEDULES_SET_ARRAY:
        return {
            ...state,
            newSchedules: action.newSchedules,
        };
    default:
        return state;
    }
};

export default entriesReducer;
