import { betaRelease } from '../appParams';

export const APP_STAGE_PARAM_UAT = 'uat';
export const APP_STAGE_PARAM_PROD = 'prod';
export const APP_STAGE_PARAM_DEV = 'test';
export const APP_ENV_PARAM_PROD = 'production';
export const APP_ENV_PARAM_DEV = 'development';
export const APP_ENV_PARAM_TEST = 'test';


export const getAppEnvAndStage = () => { 
   
    let node_env = null;
    let node_app_stage = null;
    try {
        // eslint-disable-next-line no-undef
        node_env = process.env.NODE_ENV;
        // eslint-disable-next-line no-undef
        node_app_stage = process.env.REACT_APP_STAGE;
    }
    catch(err){
        console.log('AppEnvAndStage Params read err: +', err);
    }
    
    if (node_env === APP_ENV_PARAM_DEV) {
        return {env:APP_ENV_PARAM_DEV, stage:APP_STAGE_PARAM_DEV};
    } 

    if (node_env === APP_ENV_PARAM_TEST) {        
        return {env: APP_ENV_PARAM_TEST, stage:APP_STAGE_PARAM_DEV};
    } 

    if (node_env === APP_ENV_PARAM_PROD) {    
        if (node_app_stage === APP_STAGE_PARAM_UAT)
            return {env:APP_ENV_PARAM_PROD,  stage:APP_STAGE_PARAM_UAT};
    
        if (node_app_stage === APP_STAGE_PARAM_PROD)
            return {env:APP_ENV_PARAM_PROD, stage:APP_STAGE_PARAM_PROD};
    }
    if(betaRelease)
    {
        return {env:APP_ENV_PARAM_PROD, stage:APP_STAGE_PARAM_PROD};
    }
    return {env:node_env, stage:node_app_stage};        
};

export const getAPISelector = () => { 
    let app_api_id = null;
    try {
    // eslint-disable-next-line no-undef
        app_api_id = process.env.REACT_APP_SELECT_API_ID;
    }
    catch(err){
        console.log('AppAPISelector Params read err: +', err);
    }
    return app_api_id;
};

export const isProd = () => { 
    const {env,stage} = getAppEnvAndStage();
    if(env === APP_ENV_PARAM_PROD && stage === APP_STAGE_PARAM_PROD)
        return true;
    else 
        return false;
};
