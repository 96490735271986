import axios from 'axios';
import { setError, checkAuthenticated } from './ui';
import { convertObjToKeyVal } from './utils';

export const PUBLIC_KEYS_SET = 'PUBLIC_KEYS_SET';
export const PUBLIC_KEYS_DOWNLOAD = 'PUBLIC_KEYS_DOWNLOAD';
export const REMOTE_CONFIG_SET = 'REMOTE_CONFIG_SET';


export const setPublicKeys = (authPublicKeys) => {
    return { type: PUBLIC_KEYS_SET, authPublicKeys: authPublicKeys };
};

export const setRemoteConfig = (config) => {
    return { type: REMOTE_CONFIG_SET, config: config };
};


export const downloadPublicKeys = () => {
    return (dispatch) => {
        const config = {
            baseURL:
				'https://www.googleapis.com/robot/v1/metadata/x509/securetoken@system.gserviceaccount.com',
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                
            },
        };

        const client = axios.create(config);
        client
            .get()
            .then((response) => {
                const authPublicKeys = convertObjToKeyVal(response.data);
                dispatch(setPublicKeys(authPublicKeys));
                dispatch(checkAuthenticated());
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    };
};
