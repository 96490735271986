class CreateCustContractsEntriesRequest {
    constructor(
        customerId,
        paymentTypeId,
        priceTypeId,
        scheduleId,
        preferredStartDate,
        statusId,
        partnerId,
        paymentAmount
    ) {
        this.customerId = customerId;
        this.paymentTypeId = paymentTypeId;
        this.priceTypeId = priceTypeId;
        this.scheduleId = scheduleId;
        this.preferredStartDate = preferredStartDate;
        this.statusId = statusId;
        this.partnerId = partnerId;
        this.paymentAmount = paymentAmount;
    }

    obj() {
        const obj = {
            customerId: this.customerId,
            paymentTypeId: this.paymentTypeId,
            priceTypeId: this.priceTypeId,
            scheduleId: this.scheduleId,
            preferredStartDate: this.preferredStartDate,
            statusId: this.statusId,
            partnerId: this.partnerId,
            paymentAmount: this.paymentAmount
        };
        return obj;
    }
}
export default CreateCustContractsEntriesRequest;
