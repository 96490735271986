export const initialState = {

};

const lrnAPIReducer = (state = initialState, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

export default lrnAPIReducer;
