//Libs
import { getToken, onMessage } from 'firebase/messaging';
import { getDoc, updateDoc, setDoc, doc, collection, arrayUnion } from 'firebase/firestore';
import { useEffect } from 'react';
import { firestore } from '../firebase/Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from '../store/actions/ui';
import { getMessaging, isSupported as isSupportedMsg } from 'firebase/messaging';
import { getAppEnvAndStage, APP_ENV_PARAM_DEV, APP_STAGE_PARAM_UAT } from '../utils/environment';

//Const
import { storeDataWasNowAvailable } from '../errors/errorMessages';


const useFirebaseMessaging = () => {
    const dispatch = useDispatch();
    const userIdFromStore = useSelector((store) => store.customerStore.customerData.id);
    const apiConfigId = useSelector((store) => store.firebaseStore.selectedAPIConfigId);


    let messagingKey = null;
    const {env,stage} = getAppEnvAndStage();

    
    if(env === APP_ENV_PARAM_DEV || stage === APP_STAGE_PARAM_UAT)
    {
        messagingKey ={
            vapidKey:'BIk6wjm3cbjyjIMcDAhm_DDLbesAJhU64hKW0lgyZhDt4bxOxpuC8P7i0vXpGuUfPJi1KQI3u3SbOtCnyvaGdT4',
        };
    }
    else
    {
        messagingKey ={
            vapidKey:'BAke6YZaPnCcEafjKD8Wlbm6xyPGbopwqsDhL83jdkZ-MtC9pv1lPV1RYTehm6ANxMPWP4MxXdb1JAIj9kLO2MA',
        };
    }


    async function saveTokenToDatabase(userId, token) {
        // Add the token to the users datastore
        if(!apiConfigId)
            throw storeDataWasNowAvailable;
            
        const collectionName = apiConfigId +'_users';
        
        const usersRef = collection(firestore, collectionName);
        const userRec =  doc(firestore,collectionName,`${userId}`);
        const userDocument = await getDoc(userRec);
        
        if (userDocument.exists()) {
            await updateDoc(userRec, {
                tokens: arrayUnion(token),
            });
        } else {
            const newDoc = doc(usersRef,`${userId}`);
            await setDoc(newDoc,
                {
                    tokens: arrayUnion(token),
                });
        }
    }
    
    const getMessagingIfSupported = () => {
        return new Promise(resolve=>{
            isSupportedMsg()
                .then((supported)=>{
                    if(supported)                        
                        resolve(getMessaging());
                    else
                        return null;
                });
        });
    };
    

    useEffect(() => {
        try{
        // Get the device token
            getMessagingIfSupported()
                .then((messaging)=>{
                    if(messaging){
                        getToken(messaging, messagingKey )
                            .then((token) => {
                                return saveTokenToDatabase(userIdFromStore, token);
                            })
                            .catch(err=>{
                                console.log('Firebase Messaging getToken err: ', err);
                            });
                    // // Listen to whether the token changes
                    // return onTokenRefresh(token => {
                    //   saveTokenToDatabase(token);
                    // });
                    }
                });
        }
        catch(err)
        {
            console.log('Firebase initiation has failed ' + err);
        }
    });

    getMessagingIfSupported()
        .then((messaging=>{
            console.log('messaging receive triggered supported');
            if(messaging){
                onMessage(messaging, (payload) => {
                    dispatch(setInfo(payload));
                    console.log('messaging received payload', payload);
                });}
        }));

    return null;
};

export default useFirebaseMessaging;
