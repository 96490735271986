import * as actions from '../actions/settings';

export const initialState = {	
    authPublicKeys: [
        {
            kid: '7623e10a045140f1cfd4be0466cf80352b59f81e',
            key:
				'-----BEGIN CERTIFICATE-----\nMIIDHDCCAgSgAwIBAgIIcBWFaG8PhNcwDQYJKoZIhvcNAQEFBQAwMTEvMC0GA1UE\nAxMmc2VjdXJldG9rZW4uc3lzdGVtLmdzZXJ2aWNlYWNjb3VudC5jb20wHhcNMjAw\nNjMwMDkxOTU3WhcNMjAwNzE2MjEzNDU3WjAxMS8wLQYDVQQDEyZzZWN1cmV0b2tl\nbi5zeXN0ZW0uZ3NlcnZpY2VhY2NvdW50LmNvbTCCASIwDQYJKoZIhvcNAQEBBQAD\nggEPADCCAQoCggEBAJmDeBuy9OSixAsFbbfT9aoERlzCwulBhbtt+27CME3+A31t\nrIkm6bw2ilKibhQw7/L3f5UlHeuGiCeBqZM3MHM4Vq27QsN+8oRq5BZ2ItFfZxV7\nML4oUxj8+PeUskTzRAdUt8WdB0Pp8gL6G/RoNPoS9lHot/MBad/fZNISTGxJSD0z\nTzeuELUp/kd3ohTk8LBlmVfvLTx/NTUeJ+YME3PeXMHNwjMNl4dsT0kJSNdbT0a3\nRXOa/rrBsZBLKRhVyhjbaMbJVLIveedn9Mhn7iYpGiigf2OwZI1TczvlVbMUG/p8\nKY1t+5p9vOVAPt+teiz8iA8ul0f8WAJG9+e8yQsCAwEAAaM4MDYwDAYDVR0TAQH/\nBAIwADAOBgNVHQ8BAf8EBAMCB4AwFgYDVR0lAQH/BAwwCgYIKwYBBQUHAwIwDQYJ\nKoZIhvcNAQEFBQADggEBAJSMxAgE1i/K8PlM18glz/malrgQ9ZAnvU+yIE6OcQmh\nTUspnXcD788PznUZpg8cTqu4w8idaqlKX7Mb7UVkCu2ciY3KX0wJN623k6//+Mrq\nIaP1kpS5Ks3u3IuJ5fZ/suUaNJ385PcTwptoMXyngCIT55HZU92fdfDOlZ2+FnHt\n2vf3GkWD1NCXsg5Iu6hh+MEibPY5ndWA1nIqnuwpE4IFUKOu3vuf0jW1axmqfnsM\ns/oVxw7/Z/EQHctij2GGJVVgyiRne6ftJcTZwdlF/ziWUDUfPLLz6LQFUCR+UI9b\nVUV7ro+FjhI4VP4+m+WFtMCI8KtqzUMTo6WVX72MR6Y=\n-----END CERTIFICATE-----\n',
        },
        {
            kid: '7d554f0c12c6470fb1852f79dbf64f8c843bb1d7',
            key:
				'-----BEGIN CERTIFICATE-----\nMIIDHDCCAgSgAwIBAgIIDnuulHZ23UwwDQYJKoZIhvcNAQEFBQAwMTEvMC0GA1UE\nAxMmc2VjdXJldG9rZW4uc3lzdGVtLmdzZXJ2aWNlYWNjb3VudC5jb20wHhcNMjAw\nNjIyMDkxOTU3WhcNMjAwNzA4MjEzNDU3WjAxMS8wLQYDVQQDEyZzZWN1cmV0b2tl\nbi5zeXN0ZW0uZ3NlcnZpY2VhY2NvdW50LmNvbTCCASIwDQYJKoZIhvcNAQEBBQAD\nggEPADCCAQoCggEBAKMeK3daZAWA4FMAAi6s47XuXJgQiAJrWUkVhiipgdOH7/gv\ncO2ztwSi2eBdBHyBiqH1AV/3zLeJoW/trvbzGqN6m3aa7N6fOSfBolcyoIOPFpDd\nNdqmKTkVNXtZaj0GZR8Zi8T3hLrqCZ6fs8a914shOo+2C0c12SLHX/YZCJ+y+skB\noMEEnQi4bBeeF6fOzvjC5wCouoILHH6+VqGA/3SzayH2Nwx/bnjNas5qZ+4IYkhL\nOqUCkkQFq8yfa5FPS3ip0cMPiDQ4h0LDrHfqyzAk266Sp/Yo+25x9t/aWVdNByuY\n3CMn0hExnw1aajIsp82RY6wlBIObc39hmT2McdcCAwEAAaM4MDYwDAYDVR0TAQH/\nBAIwADAOBgNVHQ8BAf8EBAMCB4AwFgYDVR0lAQH/BAwwCgYIKwYBBQUHAwIwDQYJ\nKoZIhvcNAQEFBQADggEBAHf8xY8sapqEAQYOHaKURMVtQvnT8iM2vAKeMDxLdElI\n7MIAFc63KgQcLMVJt67sQnJ5LiYlFYNYzN/QVkaS5hKXgxPdn9U8pjkC2m1reYBq\npK/KWeQWkBDQtkivaQZO5YWZHOr/wSbBcmtMQjoXBHgRdJrciKkuc/7z+RY61+7F\nKs8MU52qYCwGdhVeu8wdDYDs850t1Sa/sxEBJjs7bihbVyZJK3QnBNo+fnZ+IOYL\n2lcqSAUK24STapMJNp1NztLKjBw66NJhYwxsq9AV4CpCZ+0AehlgqF43a418a4hm\nyy8/ftpRu2m6YXCqfACeaoDv9BzVQ/KLHxENwF4aO24=\n-----END CERTIFICATE-----\n',
        },
        {
            kid: 'EBA756F9-A770-4173-B352-264DD4724B61',
            key:
				'-----BEGIN CERTIFICATE-----\nMIIDHDCCAgSgAwIBAgIIDnuulHZ23UwwDQYJKoZIhvcNAQEFBQAwMTEvMC0GA1UE\nAxMmc2VjdXJldG9rZW4uc3lzdGVtLmdzZXJ2aWNlYWNjb3VudC5jb20wHhcNMjAw\nNjIyMDkxOTU3WhcNMjAwNzA4MjEzNDU3WjAxMS8wLQYDVQQDEyZzZWN1cmV0b2tl\nbi5zeXN0ZW0uZ3NlcnZpY2VhY2NvdW50LmNvbTCCASIwDQYJKoZIhvcNAQEBBQAD\nggEPADCCAQoCggEBAKMeK3daZAWA4FMAAi6s47XuXJgQiAJrWUkVhiipgdOH7/gv\ncO2ztwSi2eBdBHyBiqH1AV/3zLeJoW/trvbzGqN6m3aa7N6fOSfBolcyoIOPFpDd\nNdqmKTkVNXtZaj0GZR8Zi8T3hLrqCZ6fs8a914shOo+2C0c12SLHX/YZCJ+y+skB\noMEEnQi4bBeeF6fOzvjC5wCouoILHH6+VqGA/3SzayH2Nwx/bnjNas5qZ+4IYkhL\nOqUCkkQFq8yfa5FPS3ip0cMPiDQ4h0LDrHfqyzAk266Sp/Yo+25x9t/aWVdNByuY\n3CMn0hExnw1aajIsp82RY6wlBIObc39hmT2McdcCAwEAAaM4MDYwDAYDVR0TAQH/\nBAIwADAOBgNVHQ8BAf8EBAMCB4AwFgYDVR0lAQH/BAwwCgYIKwYBBQUHAwIwDQYJ\nKoZIhvcNAQEFBQADggEBAHf8xY8sapqEAQYOHaKURMVtQvnT8iM2vAKeMDxLdElI\n7MIAFc63KgQcLMVJt67sQnJ5LiYlFYNYzN/QVkaS5hKXgxPdn9U8pjkC2m1reYBq\npK/KWeQWkBDQtkivaQZO5YWZHOr/wSbBcmtMQjoXBHgRdJrciKkuc/7z+RY61+7F\nKs8MU52qYCwGdhVeu8wdDYDs850t1Sa/sxEBJjs7bihbVyZJK3QnBNo+fnZ+IOYL\n2lcqSAUK24STapMJNp1NztLKjBw66NJhYwxsq9AV4CpCZ+0AehlgqF43a418a4hm\nyy8/ftpRu2m6YXCqfACeaoDv9BzVQ/KLHxENwF4aO24=\n-----END CERTIFICATE-----\n',
        },
    ],
    remoteConfig:[]
};

const settingsReducer = (state = initialState, action) => {	
    switch (action.type) {
		
    case actions.PUBLIC_KEYS_SET:
        return {
            ...state,
            authPublicKeys: action.authPublicKeys,
        };
    case actions.REMOTE_CONFIG_SET:
        return {
            ...state,
            remoteConfig: action.config,
        };
    default:
        return state;
    }
};

export default settingsReducer;
