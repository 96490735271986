import { createStore, applyMiddleware, compose } from 'redux';
//import createSagaMiddleware from "redux-saga"
import thunk from 'redux-thunk';

import rootReducer from './reducers';

//import rootSaga from "./sagas"
//const sagaMiddleware = createSagaMiddleware()

// (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers =
  // eslint-disable-next-line no-undef
  (process.env.NODE_ENV === 'development'
      ? typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null) || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

//sagaMiddleware.run(rootSaga)

export default store;
