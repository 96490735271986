
export const action_postpone_register ={
    name:'postpone_action',
    param: { action:'requested'}
};
export const action_postpone_removed ={
    name:'postpone_action',
    param: { action:'removed'}
};
export const action_proposal_sign_up ={
    name:'proposal_sign_up',
    param: null
};


export const LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DANCE = {id:'new_classes_search_dance', name:'Search new classes dance'};
export const LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_DAY = {id:'new_classes_search_day', name:'Search new classes day'};
export const LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_LEVEL = {id:'new_classes_search_level', name:'Search new classes level'};
export const LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SEARCH_TEACHER = {id:'new_classes_search_teacher', name:'Search new classes teacher'};
export const LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_SELECTED = {id:'new_classes_selected', name:'Selected new class'};
export const LOG_EVENT_ITEM_LIST_IDS_NEW_CLASSES_PURCHASED = {id:'new_classes_purchased', name:'Purchased new class'};




export const categoryFromCustSignup = (catNo, event) => {
    switch (catNo) {
    case 1:
        return event['EventName'];
    case 2:
        return event['Day'];
    case 3:
        return event['Level'];
    case 4:
        return event['InstructorsNamesLong'];    
    default:
        break;
    }
};

export const getItemFromCustSignup = (custSignup, discount, logEventItemListDef, price, quantity) => { 
    const item =   {
        item_id: custSignup.Id,
        item_name: custSignup.EventName + ' ' +custSignup.EventNameDetails + ' ' + custSignup.Day + ' ' + custSignup.EventHour + ' ' + custSignup.Level,
        // affiliation: null,
        // coupon: '',
        currency: 'PLN',
        discount: discount,
        index: custSignup.ScheduleId,
        item_brand: '',
        item_category: categoryFromCustSignup(1, custSignup),
        item_category2: categoryFromCustSignup(2, custSignup),
        item_category3: categoryFromCustSignup(3, custSignup),
        item_category4: categoryFromCustSignup(4, custSignup),
        item_category5:'',
        item_list_id: logEventItemListDef.id,
        item_list_name: logEventItemListDef.name,
        item_variant:'',
        location_id:'',
        price:price??0,
        quantity:quantity??0  };
    return item;
};