import {
    SET_AUTH_CREDENTIAL,
    SET_CUST_ACHIEVEMENTS,
    SET_CUST_CONTACTS,
    SET_CUST_DATA,
    SET_CUST_DETAILS,
    SET_CUST_INVITES,
    SET_CUST_PASSES,
    SET_CUST_SEARCH_RES,
    SET_CUST_SOCIALSCORE,
    SET_CUST_USER_PROFILE,
} from '../actions/customers';

export const initialState = {
    customerData: {
        id: 0,
        firstName: '',
        lastName: '',
        username: '',
        gender: '',
    },
    customerDetails: {
        Id: 0,
        FirstName: '',
        LastName: '',
        Username: '',
        Gender: '',
        Email: '',
        PhoneNo: '',
        Balance: 0,
        Deposit: 0,
        BlockedDeposit: 0,
        SocialScores: [],
    },
    customerAchievements: [
        {
            EventId: null,
            LevelId: null,
            EventName: '',
            LevelName: '',
        },
    ],
    custSearchResults: [],
    userProfile: [],
    contacts: [],
    invites: [],
    authData: [],
    entryPasses: [],
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_CUST_DATA:
        return {
            ...state,
            customerData: action.custData,
        };

    case SET_CUST_DETAILS:
        return {
            ...state,
            customerDetails: action.customerDetails,
        };
    case SET_CUST_ACHIEVEMENTS:
        return {
            ...state,
            customerAchievements: action.customerAchievements,
        };
    case SET_CUST_SEARCH_RES:
        return {
            ...state,
            custSearchResults: action.custSearch,
        };
    case SET_CUST_USER_PROFILE:
        return {
            ...state,
            userProfile: action.userProfile,
        };
    case SET_CUST_CONTACTS:
        return {
            ...state,
            contacts: action.contacts,
        };
    case SET_CUST_INVITES:
        return {
            ...state,
            invites: action.invites,
        };
    case SET_CUST_SOCIALSCORE:
        //TODO refactor
        // eslint-disable-next-line no-case-declarations
        const contact = action.contact;
        // eslint-disable-next-line no-case-declarations
        const contactScore = { ...contact.Scores };
        contactScore.ScoreCategory1 =
        action.cat === 1
            ? contactScore.ScoreCategory1 + 1
            : contactScore.ScoreCategory1;
        contactScore.ScoreCategory2 =
        action.cat === 2
            ? contactScore.ScoreCategory2 + 1
            : contactScore.ScoreCategory2;
        contactScore.ScoreCategory3 =
        action.cat === 3
            ? contactScore.ScoreCategory3 + 1
            : contactScore.ScoreCategory3;

        //TODO refactor
        // eslint-disable-next-line no-case-declarations
        const newContacts = { ...state.contacts };

        newContacts[action.contactIdx].Scores = contactScore;
        newContacts[action.contactIdx].reduxUpdate = Date.now().toString();

        // return {
        // 	...state,
        // 	contacts: {
        // 		...state.contacts,
        // 		[action.contactIdx]: {
        // 			...state.contacts[action.contactIdx],
        // 			Status: 22,
        // 			Scores : {...state.contacts.Scores}
        // 		},
        // 	},
        // };
        return { ...state, contacts: Object.values(newContacts) };
    case SET_AUTH_CREDENTIAL:
        return {
            ...state,
            authData: action.credential,
        };
    case SET_CUST_PASSES:
        return {
            ...state,
            entryPasses: action.entryPasses,
        };
    default:
        return state;
    }
};

export default customerReducer;
