import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {
    setError,
    setInfo,
    setMessage,
    setWarning,
} from '../../../store/actions/ui';

const Toastify = () => {
    const error = useSelector((state) => state.uiStore.error);
    const message = useSelector((state) => state.uiStore.message);
    const info = useSelector((state) => state.uiStore.info);
    const warn = useSelector((state) => state.uiStore.warning);
    const dispatch = useDispatch();

    const display = () => {
        if (error) {
            toast.error(error.message, {
                autoClose: 8000,
            });
            dispatch(setError(null));
        }
        if (message) {
            toast.success(message.message, {
                autoClose: 4000,
            });
            dispatch(setMessage(null));
        }
        if (info) {
            toast.info(info, {
                autoClose: 7000,
            });
            dispatch(setInfo(null));
        }
        if (warn) {
            toast.warn(warn, {
                autoClose: 15000,
            });
            dispatch(setWarning(null));
        }
    };

    /* eslint-disable */
	useEffect(() => {
		display();
		return () => {};
	}, [error, message, info, warn]);

	return (
		<div>
			<ToastContainer
				position='bottom-right'
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default Toastify;
