/* eslint-disable no-undef */
//Libs
import React from 'react';
import { useSelector } from 'react-redux';

//Components
import './App.css';
import Layout from './hoc/Layout/Layout';
import ProgressBarModal from './components/UI/ProgressBarModal/ProgressBarModal';

//Hooks
import useConfigs from './hooks/useConfigs';

function App() {
    const apiConfigId = useSelector((store) => store.firebaseStore.selectedAPIConfigId);
    const configurationLoaded = useSelector(
        (state) => state.uiStore.configurationLoaded
    );
    useConfigs();

    return (
        <div className='App app-max-size' data-theme={apiConfigId}>
            <ProgressBarModal />            
            {configurationLoaded ? <Layout></Layout> : null}
        </div>
    );
}

export default App;
