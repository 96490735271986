export const EVENT_SET_ARRAY = 'EVENT_SET_ARRAY';
export const EVENT_SET_ELEMENT = 'EVENT_SET_ELEMENT';
export const EVENT_SET_CHANGING = 'EVENT_SET_CHANGING';

export const SIGNUP_SET_ARRAY = 'SIGNUP_SET_ARRAY';
export const PROPOSALS_SET_ARRAY = 'PROPOSALS_SET_ARRAY';
export const NEW_SCHEDULES_SET_ARRAY = 'NEW_SCHEDULES_SET_ARRAY';


export const setEvetnsArray = eventsList => {
    return { type: EVENT_SET_ARRAY, events: eventsList };
};

export const setEvetnsArrayElement = (event, oldId) => {
    return { type: EVENT_SET_ELEMENT, event: event, oldId: oldId };
};

export const setEventBeingChanged = (id, stop) => {
    return { type: EVENT_SET_CHANGING, id: id, remove: stop };
};

export const setSignupsArray = signups => {
    return { type: SIGNUP_SET_ARRAY, signups: signups };
};

export const setProposalsArray = proposals => {
    return { type: PROPOSALS_SET_ARRAY, proposals: proposals };
};

export const setNewSchedulesArray = newSchedules => {
    return { type: NEW_SCHEDULES_SET_ARRAY, newSchedules: newSchedules };
};