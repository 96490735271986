import { combineReducers } from 'redux';

import eventsReducer from './reducers/events';
import customerReducer from './reducers/customer';
import uiReducer from './reducers/ui';
import calendarReducer from './reducers/calendar';
import lrnAPIReducer from './reducers/lrnAPI';
import settingsReducer from './reducers/settings';
import messagingReducer from './reducers/messaging';
import facebookReducer from './reducers/facebook';
import firebaseReducer from './reducers/firebase';

const rootReducer = combineReducers({
    eventsStore: eventsReducer,
    customerStore: customerReducer,
    uiStore: uiReducer,
    calendarStore: calendarReducer,
    lrnApiStore: lrnAPIReducer,
    settingsStore: settingsReducer,
    messagingStore: messagingReducer,
    facebookStore: facebookReducer,
    firebaseStore: firebaseReducer,
});
export default rootReducer;
