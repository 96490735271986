import React from 'react';

import classes from './Backdrop.module.css';

const Backdrop = ({show, clicked}) => {
    //const dispatch = useDispatch();

    // const modalClose = () => {
    // 	dispatch(setModalVisibility(false));
    // };

    return (
        <div id='backdrop' >
            {show ? (
                <div className={classes.Backdrop} onClick={clicked}></div>
            ) : null}
        </div>
    );
};

export default Backdrop;
