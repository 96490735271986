import { httpLrnAPIClient } from '../../httpClient/axiosClient';
import {
    setError,
    logout,
    LOCAL_STORAGE_PATH_FIREBASE_TOKEN,
    LOCAL_STORAGE_PATH_API_TOKEN,
    LOCAL_STORAGE_PATH_CUTOMER_DATA,
    showProgressBar,
    loggedInToApi,
    setSignupRequired,
    checkToken,
    setMessage,
    setInfo,
} from './ui';
import {
    setEventBeingChanged,
    setProposalsArray,
    setNewSchedulesArray,
} from './events';
import PropTypes from 'prop-types';
import CreateCustContractsEntriesRequest from '../../models/requests/CreateCustContractsEntriesRequest';
import {
    setCustAchievements,
    setCustContacts,
    setCustDetails,
    setCustInvites,
    setCustPasses,
    setCustSearchResults,
    setCustUserProfile,
    updateCustSocialScore,
} from './customers';
import { analytics } from '../../firebase/Firebase';
import {
    action_proposal_sign_up,
} from '../../analytics/events';
import { logEvent } from 'firebase/analytics';

// Utils

import { apiUnknownResponseError } from '../lrnAPI/exceptions';


export const SET_API_CONFIG = 'SET_API_CONFIG';
export const GET_CUSTOMER_EVENTS = 'GET_CUSTOMER_EVENTS';

export const setAPIConfig = (config) => {
    return { type: SET_API_CONFIG, config: config };
};

const saveFirebaseToken = (firebaseToken) => {
    localStorage.setItem(LOCAL_STORAGE_PATH_FIREBASE_TOKEN, firebaseToken);
};

const saveApiToken = (apiToken) => {
    localStorage.setItem(LOCAL_STORAGE_PATH_API_TOKEN, apiToken);
};

const saveCustomerData = (customerData) => {
    localStorage.setItem(
        LOCAL_STORAGE_PATH_CUTOMER_DATA,
        JSON.stringify(customerData)
    );
    document.cookie =
		'dsa_userEmail=' +
		customerData.username +
		'; expires=Fri, 31 Dec 9999 23:59:59 GMT';
};

export const sendRequest = (endPoint, data, apiToken, type) => {
    return new Promise((resolve, reject) => {
        httpLrnAPIClient(apiToken).then((apiClient) => {
            // console.log('!!!API request!!! endpoint', endPoint);
            // console.log('!!!API request!!! data', data);
            let response = null;
            if (type === 'post' || type === '' || !type) {
                response = apiClient.post(endPoint, data);
            }
            if (type === 'get') {
                response = apiClient.get(endPoint);
            }
            if (type === 'delete') {
                response = apiClient.delete(endPoint);
            }
            if (type === 'patch') {
                response = apiClient.patch(endPoint, data);
            }
            if (type === 'put') {
                response = apiClient.put(endPoint);
            }

            response
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {      
                    console.log('err', err);
                    console.log('err.response', err.response);
                    if (err.response) {
                        switch (err.response.status) {
                        case 401:
                            //auth error
                            //user not found in BE
                            if (err.response.data.status) {
                                reject({
                                    message: err.response.data.message,
                                    status: 401,
                                    apiStatus: err.response.data.status,
                                });
                            } else {
                                reject({ message: err.message, status: 401 });
                            }
                            break;
                        case 400:
                            if (err.response.data.message) {
                                reject({
                                    message: err.response.data.message,
                                    status: err.response.status,
                                    apiStatus: err.response.data.status,
                                });
                            } else if (err.response.data.title) {
                                reject({
                                    message: 'Błąd Serwisu. ' + err.response.data.title,
                                    status: err.response.status,
                                    apiStatus: err.response.status,
                                });
                            } else if (err.response.data) {
                                reject({
                                    message: 'Błąd Serwisu. ' + err.response.data,
                                    status: err.response.status,
                                    apiStatus: err.response.status,
                                });
                            } else {
                                reject({
                                    message: 'Błąd Serwisu. ' + err.response,
                                    status: err.response.status,
                                    apiStatus: err.response.data.status,
                                });
                            }

                            break;
                        case 403:
                            reject({
                                message: err.response.data.message,
                                status: err.response.status,
                                apiStatus: err.response.data.status,
                            });

                            break;
                        case 406:
                            reject({
                                message: err.response.data,
                                status: err.response.status,
                                apiStatus: err.response.status,
                            });

                            break;
                        case 424:
                            reject({
                                message: err.response.data.message,
                                status: err.response.status,
                                apiStatus: err.response.data.status,
                            });

                            break;
                        case 500:
                            reject({
                                message: 'Błąd Serwisu. ' + err.response.data,
                                status: err.response.status,
                                apiStatus: err.response.data.status,
                            });
                            break;
                        case 304:
                            reject({
                                message: 'Nie udało się zaktualizować statusu Twojego zapisu. Skontaktuj się z nami w celu dokonania zmiany.',
                                status: err.response.status,
                                apiStatus: err.response.data.status,
                            });
                            break;
                        default:
                            //unknown error
                            reject({
                                message:
										'Wystąpił nieznany błąd. ' +
										err.response.status +
										' ' +
										err.response?.data?.Value,
                                status: err.response.status,
                            });
                            break;
                        }
                    } else if (err.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        reject(err);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        reject(err);
                    }
                });
        });
    });
};

export const login = (firebaseToken) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const state = getState();
            const authPublicKeys = state.settingsStore.authPublicKeys;

            //save token to storage
            if (!firebaseToken) {
                dispatch(setError({ message: 'Missing firebase token' }));
                return false;
            }

            const chkTokenRes = checkToken(firebaseToken, 'all', authPublicKeys);
            if (!chkTokenRes.isCorrect) {
                dispatch(logout());
                dispatch(setError(chkTokenRes));
                return false;
            }

            dispatch(showProgressBar(true));

            const model = {
                Token: firebaseToken,
            };
            saveFirebaseToken(firebaseToken);
            //send login request to API
            let apiResponse = sendRequest('auth/signin', model);
            apiResponse
                .then((response) => {
                    switch (response.status) {
                    case 200:
                        saveApiToken(response.data.token);
                        saveCustomerData(response.data);
                        dispatch(loggedInToApi(response.data));
                        resolve(response.data);
                        break;
                    default:
                        dispatch(setError(response));
                        reject(apiUnknownResponseError(response.status));
                        break;
                    }
                    dispatch(showProgressBar(false));
                })
                .catch((err) => {
                    switch (err.status) {
                    case 401:
                        if (err.apiStatus === 1) dispatch(setSignupRequired(true));
                        if (err.apiStatus === 2) dispatch(logout());							
                        break;
                    case 403:
                        if (err.apiStatus === 3) {
                            dispatch(setError({message:err.message??'Error 403 - unknown reason'}));
                            dispatch(logout());
                        }
                        break;
                    default:
                        dispatch(setError({message:err.message??'Error - unknown'}));
                        dispatch(logout());
                        break;
                    }
                    dispatch(showProgressBar(false));
                    reject(err);							
                });
        });
    };
};

//#region API get data

export const getCustomerProposals = (custId, selectedDate, apiToken) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing customer Id to get Proposals' }));
            return false;
        }
        dispatch(showProgressBar(true));

        const model = {
            EventsDate: selectedDate,
        };


        const endpoint = 'CustEvents/customer/' + custId + '/proposals';
        sendRequest(endpoint, model, apiToken)
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setProposalsArray(response.data));
                    break;
                case 204:
                    dispatch(setProposalsArray([]));
                    break;
                case 401:
                    dispatch(logout());
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                switch (err.status) {
                case 401:
                    dispatch(logout());
                    break;
                default:
                    break;
                }
                dispatch(setError(err));
            });
    };
};

export const getNewSchedules = (custId, apiToken) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing customer Id to get NewSchedules' }));
            return false;
        }
        dispatch(showProgressBar(true));

        sendRequest('Schedules/customer/' + custId, null, apiToken, 'get')
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setNewSchedulesArray(response.data));
                    break;
                case 204:
                    dispatch(setNewSchedulesArray([]));
                    break;
                case 401:
                    dispatch(logout());
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                switch (err.status) {
                case 401:
                    dispatch(logout());
                    break;
                default:
                    break;
                }
                dispatch(setError(err));
            });
    };
};

export const getCustomerDetails = (custId, apiToken) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing customer Id to get Details' }));
            return false;
        }
        dispatch(showProgressBar(true));

        sendRequest('Customers/' + custId, null, apiToken, 'get')
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setCustDetails(response.data));
                    break;
                case 401:
                    dispatch(logout());
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                switch (err.status) {
                case 401:
                    dispatch(logout());
                    break;
                default:
                    break;
                }
                dispatch(setError(err));
            });
    };
};

export const getCustomerAchievementsDetails = (custId, apiToken) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing customer Id to get Achievements' }));
            return false;
        }
        dispatch(showProgressBar(true));

        sendRequest(
            'Customers/custachievementsdetails/' + custId,
            null,
            apiToken,
            'get'
        )
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setCustAchievements(response.data));
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                dispatch(setError(err));
            });
    };
};

export const searchContacts = (searchString, apiToken, custId) => {
    return (dispatch) => {
        //save token to storage
        if (!searchString) {
            dispatch(setError({ message: 'Missing user name' }));
            return false;
        }
        dispatch(showProgressBar(true));

        sendRequest(
            'Customers/searchcontacts/' + searchString + '?requesterCustId=' + custId,
            null,
            apiToken,
            'get'
        )
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setCustSearchResults(response.data));
                    break;
                case 204:
                    dispatch(
                        setCustSearchResults([{ Id: 0, FirstName: 'Brak wyników' }])
                    );
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                dispatch(setError(err));
            });
    };
};

export const loadCustUserProfile = (custId, apiToken) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing user name' }));
            return false;
        }
        dispatch(showProgressBar(true));

        sendRequest('SocialModule/getsettings/' + custId, null, apiToken, 'post')
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setCustUserProfile(response.data));
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                dispatch(setError(err));
            });
    };
};

export const changeCustUserProfileSetting = (custId, apiToken, field) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing user name' }));
            return false;
        }
        dispatch(showProgressBar(true));
        sendRequest(
            'SocialModule/setshareapproval/' + field + '/' + custId,
            null,
            apiToken,
            'patch'
        )
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(loadCustUserProfile(custId, apiToken));
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                dispatch(setError(err));
            });
    };
};

export const getCustomerContacts = (
    custId,
    apiToken,
    pageSize,
    searchString,
    invites
) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing user name' }));
            return false;
        }
        dispatch(showProgressBar(true));

        const endpoint = invites
            ? 'SocialModule/invite/getall/'
            : 'SocialModule/getcustcontacts/';
        const searchStringParam = !searchString
            ? ''
            : '&searchString=' + searchString;

        sendRequest(
            endpoint + custId + '?pageSize=' + pageSize + searchStringParam,
            null,
            apiToken,
            'get'
        )
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    if (invites) dispatch(setCustInvites(response.data));
                    else dispatch(setCustContacts(response.data));
                    break;
                case 204:
                    if (invites) dispatch(setCustInvites([]));
                    else dispatch(setCustContacts([]));
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                dispatch(setError(err));
            });
    };
};

export const getCustomerEntryPassesData = () => {
    return (dispatch, getState) => {
        const state = getState();
        const customerDetails = state?.customerStore?.customerDetails ?? {};
        const custId = customerDetails.Id;
        const apiToken = state?.uiStore?.apiToken;

        if (!apiToken || !custId) {
            dispatch(setError({ message: 'Missing '+(!apiToken?'token':'')+(!custId?' customer Id':'')+' to get Passes' }));
            return false;
        }
        dispatch(showProgressBar(true));

        const endpoint =
			'custcontractssalesentries/entrypasses/' + custId + '/summary';
        sendRequest(endpoint, null, apiToken, 'get')
            .then((response) => {                
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setCustPasses(response.data));
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                dispatch(setError(err));
            });
    };
};

//#endregion

//#region API operations
export const eventIdStatusChange = (eventId, newStatusId) => {
    const evetntIdComponents = eventId.split('.');
    if (evetntIdComponents.length < 4) {
        return eventId;
    } else {
        evetntIdComponents[4] = newStatusId;
        return evetntIdComponents.join('.');
    }
};

export const createEventFromProposal = (event, custId, token, selectedDate) => {
    return (dispatch) => {
        //save token to storage
        if (!event) {
            dispatch(setError({ message: 'Missing request data' }));
            return false;
        }
        dispatch(showProgressBar(true));
        if (event.Id) dispatch(setEventBeingChanged(event.Id));

        sendRequest(
            'CustEvents/customer/' + custId + '/proposals/create',
            event,
            token,
            'post'
        )
            .then((response) => {
                switch (response.status) {
                case 200:
                    dispatch(
                        setMessage({
                            timestamp: new Date().toISOString(),
                            message:
									'Zapis wykonany. Szczegóły możesz sprawdzić w Kalendarzu lub Zapisach.',
                        })
                    );
                    dispatch(getCustomerProposals(custId, selectedDate, token));
                    logEvent(analytics, action_proposal_sign_up.name, action_proposal_sign_up.param);
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
                dispatch(showProgressBar(false));
                if (event.Id) dispatch(setEventBeingChanged(event.Id, true));
            })
            .catch((err) => {
                switch (err.status) {
                default:
                    dispatch(setError(err));
                    break;
                }
                dispatch(showProgressBar(false));
                if (event.Id) dispatch(setEventBeingChanged(event.Id, true));
            });
    };
};
createEventFromProposal.prototype = {
    request: PropTypes.instanceOf(CreateCustContractsEntriesRequest),
};

export const customerContactsInviteResponse = (
    custId,
    apiToken,
    relationshipId,
    accept
) => {
    return (dispatch) => {
        return new Promise((resolve) => {
            if (!custId) {
                dispatch(setError({ message: 'Missing user name' }));
                return false;
            }
            dispatch(showProgressBar(true));
            const op = accept ? 'accept/' : 'decline/';
            const endpoint =
				'SocialModule/invite/' +
				op +
				custId +
				'?relationshipId=' +
				relationshipId;

            sendRequest(endpoint, null, apiToken, 'patch')
                .then((response) => {
                    dispatch(showProgressBar(false));
                    switch (response.status) {
                    default:
                        dispatch(setError(response));
                        break;
                    }
                    resolve();
                })
                .catch((err) => {
                    dispatch(showProgressBar(false));
                    dispatch(setError(err));
                    resolve();
                });
        });
    };
};

export const customerContactsInviteSend = (
    custId,
    apiToken,
    inviteForCustId
) => {
    return (dispatch) => {
        //save token to storage
        if (!custId) {
            dispatch(setError({ message: 'Missing user name' }));
            return false;
        }
        dispatch(showProgressBar(true));

        const endpoint =
			'SocialModule/invite/send/' +
			custId +
			'?inviteForCustId=' +
			inviteForCustId;

        sendRequest(endpoint, null, apiToken, 'put')
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(setCustContacts(response.data));
                    break;
                case 202:
                    dispatch(
                        setError({
                            message:
									'Twoje zaproszenie zostało odrzucone wcześniej. Zaproszenie zostało wysłane ponownie.',
                        })
                    );
                    break;
                case 208:
                    dispatch(
                        setError({
                            message: 'Kontakt już istnieje.',
                        })
                    );
                    break;
                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                dispatch(showProgressBar(false));
                dispatch(setError(err));
            });
    };
};

export const customerSocialScoreSet = (
    custFromId,
    apiToken,
    custToId,
    categoryNo,
    contactIdx,
    contactItem
) => {
    return (dispatch) => {
        //save token to storage
        if (!custFromId || !custToId) {
            dispatch(setError({ message: 'Missing cust id' }));
            return false;
        }

        dispatch(showProgressBar(true));

        const endpoint =
			'SocialModule/scores/set/' +
			custFromId +
			'?custToId=' +
			custToId +
			'&cat=' +
			categoryNo;

        sendRequest(endpoint, null, apiToken, 'put')
            .then((response) => {
                dispatch(showProgressBar(false));
                switch (response.status) {
                case 200:
                    dispatch(
                        updateCustSocialScore(
                            custToId,
                            categoryNo,
                            contactIdx,
                            contactItem
                        )
                    );
                    break;

                default:
                    dispatch(setError(response));
                    break;
                }
            })
            .catch((err) => {
                switch (err.status) {
                case 406:
                    dispatch(setInfo(err.message));
                    break;
                default:
                    dispatch(setError(err));
                    break;
                }
                dispatch(showProgressBar(false));
            });
    };
};

export const checkUserNameExists = (userName) => {
    return (dispatch) => {
        return new Promise((resolve) => {
            if (!userName) {
                dispatch(setError({ message: 'Missing user name' }));
                return false;
            }
            const endpoint = 'Customers/username/exists/' + userName;
            sendRequest(endpoint, null, null, 'get')
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    dispatch(setError(err));
                    resolve();
                });
        });
    };
};

export const customerDataSetFacebookName = (name, apiToken, userId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            if (!apiToken || !userId) {
                dispatch(setError({ message: 'Missing token or user id' }));
                return false;
            }

            if (!name && name !== '') {
                dispatch(setError({ message: 'Missing parameter' }));
                return false;
            }
            dispatch(showProgressBar(true));

            const endpoint = 'customers/' + userId + '/facebook/name?value=' + name;

            sendRequest(endpoint, null, apiToken, 'put')
                .then((response) => {
                    dispatch(showProgressBar(false));
                    switch (response.status) {
                    case 200:
                        resolve();
                        break;

                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                })
                .catch((err) => {
                    reject();
                    switch (err.status) {
                    default:
                        dispatch(setError(err));
                        break;
                    }
                    dispatch(showProgressBar(false));
                });
        });
    };
};

export const customerDetailsUpdate = (field, value) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const state = getState();
            const customerDetails = state?.customerStore?.customerDetails ?? {};
            const userId = customerDetails.Id;
            const apiToken = state?.uiStore?.apiToken;

            if (!apiToken || !userId) {
                dispatch(setError({ message: 'Missing token or user id' }));
                return false;
            }
            sendRequest(
                `customers/${userId}/modify/${field.toLowerCase()}`,
                { newValue: value },
                apiToken,
                'patch'
            )
                .then((response) => {
                    switch (response.status) {
                    case 200:
                        resolve();
                        dispatch(
                            setCustDetails({
                                ...customerDetails,
                                [field]: value,
                            })
                        );
                        break;

                    default:
                        dispatch(setError(response));
                        reject();
                        break;
                    }
                })
                .catch((err) => {
                    switch (err.status) {
                    default:
                        dispatch(setError(err));
                        break;
                    }
                    reject();
                });
        });
    };
};
//#endregion
