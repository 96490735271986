export const SET_CUST_DATA = 'SET_CUST_DATA';
export const SET_CUST_DETAILS = 'SET_CUST_DETAILS';
export const SET_CUST_ACHIEVEMENTS = 'SET_CUST_ACHIEVEMENTS';
export const SET_CUST_SEARCH_RES = 'SET_CUST_SEARCH_RES';
export const SET_CUST_USER_PROFILE = 'SET_CUST_USER_PROFILE';
export const SET_CUST_CONTACTS = 'SET_CUST_CONTACTS';
export const SET_CUST_INVITES = 'SET_CUST_INVITES';
export const SET_CUST_SOCIALSCORE = 'SET_CUST_SOCIALSCORE';
export const SET_AUTH_CREDENTIAL ='SET_AUTH_CREDENTIAL';
export const SET_CUST_PASSES = 'SET_CUST_PASSES';

export const setCustData = (custData) => {
    return { type: SET_CUST_DATA, custData: custData };
};
export const setCustDetails = (customerDetails) => {
    return { type: SET_CUST_DETAILS, customerDetails: customerDetails };
};
export const setCustAchievements = (customerAchievements) => {
    return {
        type: SET_CUST_ACHIEVEMENTS,
        customerAchievements: customerAchievements,
    };
};
export const setCustSearchResults = (custSearch) => {
    return { type: SET_CUST_SEARCH_RES, custSearch: custSearch };
};
export const setCustUserProfile = (userProfile) => {
    return { type: SET_CUST_USER_PROFILE, userProfile: userProfile };
};
export const setCustContacts = (contacts) => {
    return { type: SET_CUST_CONTACTS, contacts: contacts };
};
export const setCustInvites = (invites) => {
    return { type: SET_CUST_INVITES, invites: invites };
};
export const updateCustSocialScore = (custId, cat, contactIdx, contact) => {
    return { type: SET_CUST_SOCIALSCORE, custId: custId, cat: cat, contactIdx: contactIdx, contact };
};
export const setAuthCredential = (credential) => {
    return { type: SET_AUTH_CREDENTIAL, credential: credential };
};
export const setCustPasses = (passesData) => {
    return { type: SET_CUST_PASSES, entryPasses: passesData };
};
