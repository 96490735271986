import _ from 'underscore';

export const getUniqueIdxs = (arrayOfObj, key) => {
    let result = [];
    if (arrayOfObj) {
        let i = 0;
        arrayOfObj.forEach((element) => {
            const desiredDataObj = _.pick(element, key);
            const data = _.values(desiredDataObj);
            const found = result.find((x) => x.value === data[0]);
            if (!found) {
                result.push({ index: i, value: data[0] });
            }
            i++;
        });
    }

    return result;
};

export const getUniquValues = (arrayOfObj, key) => {
    let result = [];
    if (arrayOfObj) {
        arrayOfObj.forEach((element) => {
            const desiredDataObj = _.pick(element, key);
            const data = _.values(desiredDataObj);
            if(data[0])
            {
                const found = result.find((x) => x === data[0]); 
                if (!found) {                
                    result.push(data[0]);
                }
            }
        });
    }

    return result;
};

export const getUniqueArray = (arrayOfObj, key) => {
    let result = [];
    const idxs = getUniqueIdxs(arrayOfObj, key);
    idxs.forEach((i) => {
        result.push(arrayOfObj[i.index]);
    });

    return result;
};

export const convertArrayToObj = (array) => {
    const res = [];
    let i = 0;
    array.map((x) => res.push({ id: i++, name: x }));
    return res;
};

export const createSelectOptionsArray = (inputArray) => {
    let res = [];
    if (inputArray) {
        inputArray.forEach((el) => {
            res.push({ value: el, label: el });
        });
    }
    return res;
};

export const convertObjToKeyVal = (obj) => {
    const resArray = [];
    _.mapObject(obj, (oval, okey) => {
        let el = { key: okey, value: oval };
        resArray.push(el);
    });

    return resArray;
};

export const cookieRead = (cookieName) => {
    let cookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith(cookieName+'='));
    if (cookie) {
        return cookie.split('=')[1];
    } else {
        return null;
    }
};

export const setCookie = (cookieName,cookieValue) => {
    document.cookie =
	cookieName+'='+ cookieValue + '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
};