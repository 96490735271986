import * as actions from '../actions/ui';
import jsonwebtoken from 'jsonwebtoken';

export const initialState = {
    error: '',
    message: '',
    info: '',
    warning: '',
    inProgress: false,
    isModalOn: false,
    firebaseSignedIn: false,
    apiSignedIn: false,
    firebaseToken: {},
    apiToken: {},
    signupRequired: false,
    firebaseTokenDecoded: {},
    configurationLoaded: false,
    activeMenuId: 0,
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
    case actions.SET_PROGRESS_BAR:
        return {
            ...state,
            inProgress: action.inProgress,
        };
    case actions.MODAL_ON_OFF:
        return {
            ...state,
            isModalOn: action.isOn,
        };
    case actions.SET_ERROR:
        return {
            ...state,
            error: action.error,
        };
    case actions.SET_MESSAGE:
        return {
            ...state,
            message: action.message,
        };
    case actions.SET_INFO:
        return {
            ...state,
            info: action.info,
        };
    case actions.SET_WARNING:
        return {
            ...state,
            warning: action.warn,
        };
    case actions.SET_SIGNED_IN:
        return {
            ...state,
            firebaseSignedIn: action.firebaseSignedIn,
            apiSignedIn: action.apiSignedIn,
            firebaseToken: action.firebaseToken,
            apiToken: action.apiToken,
            firebaseTokenDecoded: jsonwebtoken.decode(action.firebaseToken, {
                complete: true,
            }),
        };

    case actions.SET_SIGNUP_REQUIRED:
        return {
            ...state,
            signupRequired: action.isRequired,
        };
    case actions.SET_CONFIGURATION_LOADED:
        return {
            ...state,
            configurationLoaded: action.isLoaded,
        };
    case actions.SET_ACTIVE_MENU:
        return {
            ...state,
            activeMenuId: action.menuId,
        };
    default:
        return state;
    }
};

export default uiReducer;
