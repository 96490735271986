import React from 'react';
import messages from './resources/messages.png';
import info from './resources/info.png';

import './Icons.css';
import EventsIconSvg from './resources/svg/EventsIconSvg';
import CalendarIconSvg from './resources/svg/CalendarIconSvg';
import DashboardIconSvg from './resources/svg/DashboardIconSvg';
import ManIconSvg from './resources/svg/ManIconSvg';
import WomanIconSvg from './resources/svg/WomanIconSvg';
import DropDownIconSvg from './resources/svg/DropDownIconSvg';
import PeopleIconSvg from './resources/svg/PeopleIconSvg';

export const DashboardIcon = () => {
    return <DashboardIconSvg />;
    // <img src={dashboard} alt={props.alt} onClick={props.onClick} />;
};

export const CalendarIcon = (props) => {
    return <CalendarIconSvg onClick={props.onClick} />;
    // return <img src={calendar} alt={props.alt} onClick={props.onClick} />;
};

export const MyEventsIcon = (props) => {
    return <EventsIconSvg onClick={props.onClick} />;
};

export const MessagesIcon = (props) => {
    return <img src={messages} alt={props.alt} onClick={props.onClick} />;
};

export const InfoIcon = (props) => {
    return (
        <img
            src={info}
            alt={props.alt}
            onClick={props.onClick}
            width={props.width}
        />
    );
};

export const ManIcon = (props) => {
    return (
        <ManIconSvg onClick={props.onClick} />
    // <img
    // 	src={man}
    // 	alt={props.alt}
    // 	onClick={props.onClick}
    // 	width={props.width}
    // />
    );
};

export const WomanIcon = (props) => {
    return (
        <WomanIconSvg onClick={props.onClick} />
    // <img
    // 	src={woman}
    // 	alt={props.alt}
    // 	onClick={props.onClick}
    // 	width={props.width}
    // />
    );
};

export const PeopleIcon = (props) => {
    return (
        <PeopleIconSvg onClick={props.onClick} />
    // <img
    // 	src={woman}
    // 	alt={props.alt}
    // 	onClick={props.onClick}
    // 	width={props.width}
    // />
    );
};

export const DropDownIcon = () => {
    return <DropDownIconSvg />;
    // <img src={dashboard} alt={props.alt} onClick={props.onClick} />;
};
