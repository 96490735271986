import { customerDataSetFacebookName } from './lrnAPI';
import { setError, showProgressBar } from './ui';

export const SET_LOGIN_RESPONSE = 'SET_LOGIN_RESPONSE';
export const SET_LOGGEDIN = 'SET_DATA_FETCH_ERROR';
export const SET_DATA_FETCH_ERROR = 'SET_LOGGEDIN';

export const setLoginResponse = (resp) => {
    return { type: SET_LOGIN_RESPONSE, resp: resp };
};
export const setLoggedIn = (loggedIn) => {
    return { type: SET_LOGGEDIN, loggedIn: loggedIn };
};
export const setDataFetchError = (isError) => {
    return { type: SET_DATA_FETCH_ERROR, isError: isError };
};

const login = () => {
    return new Promise((resolve, reject) => {
        window.FB.login(
            function (response) {
                if (response.status === 'connected') {
                    resolve(response);
                } else {
                    reject(response.status);
                }
            },
            { scope: 'public_profile,email' }
        );
    });
};

export const loginStatus = () => {
    return new Promise((resolve) => {
        window.FB.getLoginStatus(function (response) {
            resolve(response);
        });
    });
};

const loggedInCheckAndLogin = (token) => {
    return new Promise((resolve, reject) => {
        loginStatus().then((r) => {
            if (r.status !== 'connected') {
                login()
                    .then((r) => {
                        resolve(r);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                if (r.authResponse.accessToken !== token) {
                    reject(r);
                } else {
                    resolve();
                }
            }
        });
    });
};

export const facebookLogin = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(showProgressBar(true));
            const state = getState();
            const token = state.facebookStore?.loginData?.authResponse?.accessToken;
            loggedInCheckAndLogin(token)
                .then((r) => {
                    dispatch(setLoginResponse(r));
                    dispatch(setLoggedIn(true));
                    dispatch(setDataFetchError(false));
                    dispatch(showProgressBar(false));
                    resolve();
                })
                .catch((res) => {
                    if (!res) {
                        dispatch(setLoggedIn(false));
                        dispatch(showProgressBar(false));
                        reject(res);
                    } else {
                        dispatch(setLoginResponse(res));
                        dispatch(setLoggedIn(true));
                        dispatch(setDataFetchError(false));
                        dispatch(showProgressBar(false));
                        resolve();
                    }
                });
        });
    };
};

const getToken = (dispatch, state) => {
    const token = state.facebookStore?.loginData?.authResponse?.accessToken;
    if (!token) {
        dispatch(
            setError({ message: 'Problem z pobieraniem danych z FB - brak tokenu' })
        );
        return null;
    } else {
        return token;
    }
};

export const getPermissions = () => {
    return (dispatch, getState) => {
        const token = getToken(dispatch, getState());
        let url = '/me/permissions';
        const tokenParam = '?access_token=' + token;
        url = url + tokenParam;
        window.FB.api(url, function () {
            //console.log('getPermissions resp', response);
        });
    };
};

export const getNameAndUpdateInDB = (beApiToken, userId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const token = getToken(dispatch, getState());
            window.FB.api(
                '/me/?access_token=' + token,
                'GET',
                { fields: 'name' },
                function (response) {
                    if (response.error) {
                        dispatch(
                            setError({
                                message:
									'Wystąpił bład podczas pobierania danych z Facebook, spróbuj ponownie.',
                            })
                        );
                        dispatch(setDataFetchError(true));
                        dispatch(setLoggedIn(false));
                        reject();
                    }
                    if (response.name) {
                        dispatch(
                            customerDataSetFacebookName(response.name, beApiToken, userId)
                        );
                        resolve(response.name);
                    }
                }
            );
        });
    };
};
