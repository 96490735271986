import * as actions from '../actions/firebase';

export const initialState = {	
    selectedAPIConfig: JSON.parse(localStorage.getItem(actions.LOCAL_STORAGE_PATH_API_CONFIG)),
    selectedAPIConfigId: localStorage.getItem(actions.LOCAL_STORAGE_PATH_API_ID)
};

const firebaseReducer = (state = initialState, action) => {
    switch (action.type) {
    case actions.SET_SELECTED_API_CONFIG:
        return {
            ...state,
            selectedAPIConfig: action.configRec,
        };
    case actions.SET_SELECTED_API_CONFIG_ID:
        return {
            ...state,
            selectedAPIConfigId: action.configRecId,
        };
    default:
        return state;
    }
};

export default firebaseReducer;
